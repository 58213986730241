<template>
  <div class="card">
    <h1>Horas no disponibles por Curso</h1>
  </div>
  <div class="card">
    <Tabs :value="activeTab" @update:value="onTabChange" scrollable>
      <TabList>
        <Tab v-for="course in courses" :key="course.id" :value="course.id">
          <strong>Curso:</strong> {{ course.name }}
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel v-for="course in courses" :key="course.id" :value="course.id">
          <DataTable :value="course.tableData">
            <Column field="hourId" header="Hora">
              <template #body="{ data }">
                <div style="min-width: 4rem">
                  <strong>{{ data.hourName }}</strong>
                  <Checkbox class="ml-2" v-model="data.checkedHour" :indeterminate="!data.all" binary
                            @change="() => allHours(course.id, data)"
                  />
                </div>
              </template>
            </Column>
            <Column v-for="day in days" :key="day.id" :field="day.id" :header="day.name">
              <template #body="{ data }">
                <Checkbox v-model="data[day.id]" binary @change="() => unmark(course.id, data, day)">
                  <template #icon>
                    <i :class="['pi', data[day.id] ? 'pi-check' : (data[day.id] === null ? 'pi-times' : 'pi-minus'), {'text-white': data[day.id]}]">
                    </i>
                  </template>
                </Checkbox>
              </template>
            </Column>
          </DataTable>
        </TabPanel>
      </TabPanels>
      <Toolbar class="border-0">
        <template #center>
          <Button icon="pi pi-save" class="p-button-primary mt-3" label="Guardar"
                  @click="save()"></Button>
        </template>
      </Toolbar>
      <Toolbar class="border-0">
        <template #center>
          <ProgressSpinner v-if="loading" style="width: 50px; height: 50px" strokeWidth="8" fill="transparent"
                           animationDuration=".5s" aria-label="Custom ProgressSpinner"/>
        </template>
      </Toolbar>
    </Tabs>
  </div>
</template>

<script>
import apiService from "@/service/api.service";
import {API} from "@/utils/modules";
import {useToast} from "primevue/usetoast";
import {sortByName} from "@/utils/funtions";

export default {
  data() {
    return {
      toast: useToast(),
      activeTab: "",
      courses: [],
      days: [],
      inactiveDays: [],
      hours: [],
      loading: false,
    };
  },
  methods: {
    async getCourses() {
      try {
        const responseHours = await apiService.getData(API, "hours");
        this.hours = sortByName(responseHours.data.hours);

        const responseDays = await apiService.getData(API, "days");
        this.days = responseDays.data.days;

        const responseUnavailabilities = await apiService.getData(API, "schedule/unavailable-hours-by-course");
        this.inactiveDays = responseUnavailabilities.data;

        const response = await apiService.getData(API, "courses");
        this.courses = response.data.map(course => ({
          ...course,
          tableData: null
        }));
        this.courses = sortByName(this.courses);
        this.activeTab = this.courses[0].id;
        await this.loadCourseData(this.activeTab);
      } catch (error) {
        this.toast.add({severity: 'error', summary: 'Error', detail: 'Error al cargar los cursos', life: 3000});
      }
      this.loading = false;
    },

    async loadCourseData(courseId) {
      const course = this.courses.find(c => c.id === courseId);
      if (!course.tableData) {
        try {
          this.loading = true;
          course.tableData = this.initializeTableData(courseId);
        } catch (error) {
          this.toast.add({
            severity: 'error',
            summary: 'Error',
            detail: `Error al cargar los horarios para el curso ${course.name}`,
            life: 3000
          });
        } finally {
          this.loading = false;
        }
      }
    },

    onTabChange(courseId) {
      this.activeTab = courseId;
      this.loadCourseData(courseId);
    },
    getUnselectedDaysAndHours() {
      let unselected = [];
      const unavailableHours = [];
      this.courses.forEach(course => {
        course.tableData?.forEach(row => {
          this.days.forEach(day => {
            if (!row[day.id]) {
              unselected.push({day: {id:day.id}, hour: {id:row.hourId}});
            }
          });
        });
        unavailableHours.push({course:{id:course.id},hours:unselected})
        unselected = [];
      });
      return unavailableHours;
    },
    initializeTableData(courseId) {
      return this.hours.map(hour => {
        const rowData = {
          hourId: hour.id,
          hourName: hour.name,
          checkedHour: true,
          all: true
        };
        this.days.forEach(day => {
          const isUnavailable = this.inactiveDays.some(unavailability =>
              unavailability.course.id === courseId &&
              unavailability.hour.id === hour.id &&
              unavailability.day.id === day.id
          );

          rowData[day.id] = !isUnavailable;
          if (isUnavailable) {
            rowData.all = false;
            rowData.checkedHour = false;
          }
        });

        return rowData;
      });
    },

    allHours(courseId, hourData) {
      const newValue = hourData.checkedHour;
      this.days.forEach(day => {
        hourData[day.id] = newValue;
        this.updateInactiveDays(courseId, hourData, day);
      });
      hourData.all = newValue;
    },

    unmark(courseId, data, day) {
      this.updateInactiveDays(courseId, data, day);
      data.all = this.days.every(day => data[day.id] === true);
    },

    updateInactiveDays(courseId, data, day) {
      const entry = {
        course: this.courses.find(course => course.id === courseId),
        day: day,
        hour: this.hours.find(hour => hour.id === data.hourId)
      };

      if (data[day.id]) {
        this.inactiveDays = this.inactiveDays.filter(item =>
            !(item.day.id === day.id && item.hour.id === data.hourId && item.course.id === courseId)
        );
      } else {
        if (!this.inactiveDays.some(item =>
            item.day.id === day.id && item.hour.id === data.hourId && item.course.id === courseId)
        ) {
          this.inactiveDays.push(entry);
        }
      }
    },

    save() {
      try {
        apiService.create(API, "schedule/unavailable-hours-by-course", this.getUnselectedDaysAndHours()).then(
            () => {
              this.$toast.add({severity: 'success', summary: 'Completado', detail: 'Registros Guardados!', life: 3000});
            },
            (error) => {
              this.$toast.add({severity: 'error', summary: 'Error', detail: error.response.data, life: 10000});
            }
        );


      } catch (error) {
        this.toast.add({severity: 'error', summary: 'Error', detail: 'Error al guardar los horarios', life: 3000});
      }
    }
  },

  async mounted() {
    this.loading = true;
    await this.getCourses();
  },
};
</script>
