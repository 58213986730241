<template>
  <div class="card">
    <h1>Horas no disponibles por Curso</h1>
  </div>
<!--  <div class="card">-->
<!--    <div class="grid formgrid">-->
<!--      <div class="form-row">-->
<!--        <FloatLabel>-->
<!--          <InputText id="name" v-model="value"/>-->
<!--          <label for="name">Nombre Horario</label>-->
<!--        </FloatLabel>-->
<!--      </div>-->
<!--      <div class="form-row">-->
<!--        <Button icon="pi pi-arrow-circle-left" class="p-button-primary mr-3" label="Generar Horario"-->
<!--                @click="postData"></Button>-->
<!--      </div>-->

<!--      <div>-->
<!--        <ProgressSpinner v-if="loading" style="width: 50px; height: 50px" strokeWidth="8" fill="transparent"-->
<!--                         animationDuration=".5s" aria-label="Custom ProgressSpinner"/>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

  <div class="card">
    <Tabs value="6-1">
      <TabList>
        <Tab v-for="course in courses" :key="course.id" :value="course.name"><strong>Curso:</strong> {{ course.name }}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel v-for="course in courses" :key="course.id" :value="course.name">
          <DataTable :value="course.hours">
            <Column field="hour" header="Hora">
              <template #body="{ data }">
                <div style="max-width: 6rem"><strong>{{ data.hora }}</strong>
                  <Checkbox class="ml-2" v-model="data.checkedHour" :indeterminate="!data.all" binary
                            @change="allHours(data)"
                  />
                </div>
              </template>
            </Column>
            <Column v-for="day in days" :key="day" :field="day" :header="day">
              <template #body="{ data }">
                <Checkbox v-model="data[day]" binary @change="unmark(data)">
                  <template #icon>
                    <i :class="['pi', data[day] ? 'pi-check' : (data[day] === null ? 'pi-times' : 'pi-minus'),{'text-white':data[day]}] ">
                    </i>
                  </template>
                </Checkbox>
              </template>
            </Column>
          </DataTable>
          <Button icon="pi pi-arrow-circle-left" class="p-button-primary mr-3" label="Guardar"
                  @click="save"></Button>
        </TabPanel>
      </TabPanels>
    </Tabs>

  </div>
</template>

<script>
import apiService from "@/service/api.service";
import {API} from "@/utils/modules";
import {useToast} from "primevue/usetoast";
import {sortByName} from "@/utils/funtions";

export default {
  name: "ScheduleView",
  data() {
    return {
      courses: [],
      check: false,
      days: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes'],
      hours: [
        {
          'hora': 'Hora 1',
          'Lunes': true,
          'Martes': true,
          'Miércoles': true,
          'Jueves': true,
          'Viernes': true,
          'all': true,
          'checkedHour': true
        },
        {
          'hora': 'Hora 2',
          'Lunes': true,
          'Martes': true,
          'Miércoles': true,
          'Jueves': true,
          'Viernes': true,
          'all': true,
          'checkedHour': true
        },
        {
          'hora': 'Hora 3',
          'Lunes': true,
          'Martes': true,
          'Miércoles': true,
          'Jueves': true,
          'Viernes': true,
          'all': true,
          'checkedHour': true
        },
        {
          'hora': 'Hora 4',
          'Lunes': true,
          'Martes': true,
          'Miércoles': true,
          'Jueves': true,
          'Viernes': true,
          'all': true,
          'checkedHour': true
        },
        {
          'hora': 'Hora 5',
          'Lunes': true,
          'Martes': true,
          'Miércoles': true,
          'Jueves': true,
          'Viernes': true,
          'all': true,
          'checkedHour': true
        },
        {
          'hora': 'Hora 6',
          'Lunes': true,
          'Martes': true,
          'Miércoles': true,
          'Jueves': true,
          'Viernes': true,
          'all': true,
          'checkedHour': true
        },
        {
          'hora': 'Hora 7',
          'Lunes': true,
          'Martes': true,
          'Miércoles': true,
          'Jueves': true,
          'Viernes': true,
          'all': true,
          'checkedHour': true
        },
        {
          'hora': 'Hora 8',
          'Lunes': true,
          'Martes': true,
          'Miércoles': true,
          'Jueves': true,
          'Viernes': true,
          'all': true,
          'checkedHour': true
        },
        {
          'hora': 'Hora 9',
          'Lunes': true,
          'Martes': true,
          'Miércoles': true,
          'Jueves': true,
          'Viernes': true,
          'all': true,
          'checkedHour': true
        },
      ],
      value: "",
      toast: useToast(),
      schedule: {},
      data: [],
      first: "",
      loading: false,
    };
  },

  methods: {
    save(){
      const unavailability = this.getUnselectedDaysAndHours();
      return apiService.create(API, 'schedule/unavailable-hours-by-course',unavailability).then(
          () => {
            this.toast.add({severity: 'success', summary: 'Completado', detail: 'Registro Creado!', life: 3000});
          },
          (error) => {
            this.toast.add({severity: 'error', summary: 'Successful', detail: error.response.data, life: 10000});
          }
      );
    },
    getUnselectedDaysAndHours() {
      let unselected = [];
      const unavailableHours = [];
      this.courses.forEach(course => {
        course.hours.forEach(row => {
          this.days.forEach(day => {
            if (!row[day]) {
              unselected.push({day: day, hour: row.hora});
            }
          });
        });
        unavailableHours.push({course:course.id,hours:unselected})
        unselected = [];
      });
      return unavailableHours;
    },
    getCourses() {
      return apiService.getData(API, 'courses').then(
          (response) => {
            this.courses = response.data
            this.courses = response.data.map(course => {
              const courseHours = JSON.parse(JSON.stringify(this.hours));
              return {...course, hours: courseHours};
            });
          },
          (error) => {
            this.toast.add({severity: 'error', summary: 'Error', detail: error.response.data, life: 10000});
          }
      );
    },
    getHours(){
      return apiService.getData(API, 'schedule/unavailable-hours-by-course').then(
          (response) => {
            this.courses = response.data
            this.courses = response.data.map(course => {
              const courseHours = JSON.parse(JSON.stringify(this.hours));
              return {...course, hours: courseHours};
            });
          },
          (error) => {
            this.toast.add({severity: 'error', summary: 'Error', detail: error.response.data, life: 10000});
          }
      );
    },
    allHours(hourData) {
      const newValue = hourData.checkedHour;
      this.days.forEach(day => {
        hourData[day] = newValue;
      });
      hourData.all = newValue;
    },
    unmark(data) {
      data.all = this.days.every(day => data[day] === true);
    },
    postData() {
      this.schedule.name = this.value;
      this.loading = true;
      apiService.create(API, 'schedule', this.schedule).then(
          (response) => {
            this.data = response.data;
            this.loading = false;
            this.toast.add({
              severity: 'success',
              summary: 'Éxito',
              detail: "Se generó correctamente el horario " + this.value + " la solicitud correctamente.",
              life: 10000
            });
          },
          (error) => {
            this.loading = false;
            this.toast.add({
              severity: 'error',
              summary: 'Error',
              detail: error.response.data,
              life: 10000
            });
          }
      );
    },

  },
  mounted() {
    this.getCourses().then(() => {
      this.courses = sortByName(this.courses);
    });
    this.getHours();
  }
};
</script>