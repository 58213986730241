<template>
  <Dialog v-model:visible="formDialog" :style="{ width: '400px' }" :header="`Detalle ${title}`" :modal="true"
          class="p-fluid" @hide="onHide">
    <div v-for="(field, index) in fields" :key="index" class="field">
      <label :for="field.model">{{ field.label }}</label>
      <component
          :is="getFieldComponent(field.type)"
          :id="field.model"
          v-model="record[field.model]"
          :required="field.required"
          :options="field.type === 'dropdown' ? dropdownOptions[field.options] : undefined"
          :optionLabel="field.props && field.props.optionLabel"
          :optionValue="field.props && field.props.optionValue"
          :class="{ 'p-invalid': submitted && field.required && !record[field.model] , 'w-full':true} "
          :minFractionDigits="field.minFractionDigits"
          :timeOnly="field.timeOnly"
          hourFormat="12"
          v-bind="field.props"
      />
      <small class="p-invalid" v-if="submitted && field.required && !record[field.model]">
        {{ field.label }} es requerido.
      </small>
    </div>
    <template #footer>
      <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
      <Button v-if="!edit" label="Confirmar" icon="pi pi-check" class="p-button-text" @click="createRecord"/>
      <Button v-if="edit" label="Editar" icon="pi pi-check" class="p-button-text" @click="updateRecord"/>
    </template>
  </Dialog>
</template>
<script>
import {useToast} from "primevue/usetoast";
import InputText from "primevue/inputtext";
import Select from "primevue/select";
import InputNumber from "primevue/inputnumber";
import DatePicker from "primevue/datepicker";

export default {
  props: {
    edit: {
      type: Boolean,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    object: {
      type: Object,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    dropdownOptions: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      toast: useToast(),
      record: {...this.object},
      submitted: false,
      formDialog: false,
    }
  },
  watch: {
    active(newValue) {
      this.formDialog = newValue;
    }
  },
  methods: {
    onHide() {
      this.submitted = false;
      this.$emit('update:active', false);
    },
    hideDialog() {
      this.formDialog = false;
      this.submitted = false;
      this.$emit('update:active', false);
    },
    createRecord() {
      if (this.validateForm())
        this.$emit('record-created', this.record);
    },
    updateRecord() {
      if (this.validateForm())
        this.$emit('record-updated', this.record);
    },
    validateForm() {
      this.submitted = true;
      return this.fields.every(field => !field.required || this.record[field.model]);
    },
    getFieldComponent(type) {
      switch (type) {
        case 'text':
          return InputText;
        case 'dropdown':
          return Select;
        case 'number':
          return InputNumber;
        case 'time':
          return DatePicker;
        default:
          return InputText;
      }
    },
    convert(obj) {
      const transformedObject = {...obj};
      this.fields.forEach(field => {
        const { model, type } = field;
        if (type === "time" && typeof transformedObject[model] === "number") {
          transformedObject[model] = new Date(transformedObject[model]);
        }
        if (type === "number" ) {
          transformedObject[model] = Number(transformedObject[model]);
        }
      });

      return transformedObject;
    }
  },
  beforeUpdate() {
    const transformedObject = this.convert(this.object);
    this.record = { ...transformedObject };
  }
}
</script>
