<template>
  <div>

    <div class="card">
      <div>
        <h1> Parametrización de Calificación </h1>
      </div>
    </div>
    <div class="card">
      <Fieldset legend="Configuración de decimales" class="mb-3">
        <div class="grid formgrid mt-3">
          <div class="field col-12 xl:col-3">
            <label>Cantidad de decimales</label>
            <InputGroup>
              <InputNumber v-model="parameters.decimals" style="width: 6rem;" placeholder="Cantidad Decimales" :max="3"
                           :min="1"
                           :step="1"/>
              <InputGroupAddon>.00</InputGroupAddon>
            </InputGroup>
          </div>
          <div class="field col-12 xl:col-4 xl:col-offset-1">
            <label>Tipo de redondeo</label>
            <SelectButton v-model="selectedValue" optionLabel="label" data-key="index" :options="options"
                          aria-labelledby="basic"/>
          </div>
          <div class="field col-12 xl:col-4">
            <label>Ejemplo</label>
            <div v-if="selectedValue.index === 'UP'">
              <p>Promedio: 2.95 ->(Redondeo) 3.0</p>
              <p>Promedio: 2.96 ->(Redondeo) 3.0</p>
            </div>
            <div v-else>
              <p>Promedio: 2.95 ->(Redondeo) 2.9</p>
              <p>Promedio: 2.96 ->(Redondeo) 3.0</p>
            </div>
          </div>
        </div>
      </Fieldset>
      <Fieldset legend="Rango de Calificación" class="mb-3">
        <div class="grid formgrid mt-3">
          <div class="field col-4 xl:col-2">
            <div class="mb-3">
              <label> Rango menor </label>
            </div>
            <InputNumber v-model="parameters.less" showButtons buttonLayout="vertical" style="width: 6rem"
                         :max-fraction-digits="parameters.decimals"
                         :min="0" :max="parameters.higher" :step="calculatedStep">
              <template #incrementbuttonicon>
                <span class="pi pi-plus"/>
              </template>
              <template #decrementbuttonicon>
                <span class="pi pi-minus"/>
              </template>
            </InputNumber>
          </div>
          <div class="field col-4 xl:col-2">
            <div class="mb-3">
              <label> Rango mayor </label>
            </div>
            <InputNumber v-model="parameters.higher" showButtons buttonLayout="vertical" style="width: 6rem"
                         :max-fraction-digits="parameters.decimals"
                         :min="parameters.less" :max="100" :step="calculatedStep">
              <template #incrementbuttonicon>
                <span class="pi pi-plus"/>
              </template>
              <template #decrementbuttonicon>
                <span class="pi pi-minus"/>
              </template>
            </InputNumber>
          </div>
          <div class="field col-4 xl:col-2">
            <div class="mb-3">
              <label> Aprobación </label>
            </div>
            <InputNumber v-model="parameters.approved" showButtons buttonLayout="vertical" style="width: 6rem"
                         :max-fraction-digits="parameters.decimals"
                         :min="parameters.less" :max="parameters.higher" :step="calculatedStep" @input="calculate" @keyup="calculate">
              <template #incrementbuttonicon>
                <span class="pi pi-plus"/>
              </template>
              <template #decrementbuttonicon>
                <span class="pi pi-minus"/>
              </template>
            </InputNumber>
          </div>
          <div class="field col-4 xl:col-2">
            <Knob v-model="parameters.approved" readonly :max="parameters.higher" :value-color="color"/>
          </div>
          <div class="field col-4 xl:col-2">
            <Knob v-model="parameters.percentage" readonly :max="100" valueTemplate="{value}%"
                  :value-color="color"/>
          </div>
        </div>
      </Fieldset>
      <Toolbar class="mb-4 border-0">
        <template #center>
          <Button label="Guardar" icon="pi pi-save" class="p-button-success" @click="save"/>
        </template>
      </Toolbar>
      <!--      <Fieldset legend="Desempeño académico">-->
      <!--        <Toolbar class="mb-4 border-0">-->
      <!--          <template v-slot:end>-->
      <!--            <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew"/>-->
      <!--          </template>-->
      <!--        </Toolbar>-->
      <!--        <DataView :value="performances">-->
      <!--          <template #list="slotProps">-->
      <!--            <div v-for="(item, index) in slotProps.items" :key="index">-->
      <!--              <div class="flex flex-col sm:flex-row sm:items-center p-3"-->
      <!--                   :class="{ 'border-t border-surface-200 dark:border-surface-700': index !== 0 }">-->
      <!--                <div class="flex flex-col md:flex-row align-items-center md:items-center flex-1 gap-6">{{ item.name }}-->
      <!--                </div>-->
      <!--                <div class="flex flex-col md:flex-row justify-between md:items-center flex-1 gap-6">-->
      <!--                  <InputNumber v-model="item.initialRange" showButtons buttonLayout="vertical" style="width: 6rem"-->
      <!--                               :max-fraction-digits="fraction"-->
      <!--                               :min="less" :max="item.finalRange" :step="calculatedStep">-->
      <!--                    <template #incrementbuttonicon>-->
      <!--                      <span class="pi pi-plus"/>-->
      <!--                    </template>-->
      <!--                    <template #decrementbuttonicon>-->
      <!--                      <span class="pi pi-minus"/>-->
      <!--                    </template>-->
      <!--                  </InputNumber>-->
      <!--                </div>-->
      <!--                <div class="flex flex-col md:flex-row justify-between md:items-center flex-1 gap-6">-->
      <!--                  <InputNumber v-model="item.finalRange" showButtons buttonLayout="vertical" style="width: 6rem"-->
      <!--                               :max-fraction-digits="fraction"-->
      <!--                               :min="less" :max="higher" :step="calculatedStep">-->
      <!--                    <template #incrementbuttonicon>-->
      <!--                      <span class="pi pi-plus"/>-->
      <!--                    </template>-->
      <!--                    <template #decrementbuttonicon>-->
      <!--                      <span class="pi pi-minus"/>-->
      <!--                    </template>-->
      <!--                  </InputNumber>-->
      <!--                </div>-->
      <!--                <div class="flex flex-col md:flex-row align-items-center justify-between md:items-center flex-1">-->
      <!--                  <Button label="Eliminar" icon="pi pi-trash" class="p-button-warning mr-2 h-3rem" @click="openNew"/>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </template>-->
      <!--        </DataView>-->
      <!--      </Fieldset>-->
    </div>
  </div>
</template>
<script>

import {useToast} from "primevue/usetoast";
import apiService from "@/service/api.service";
import {API} from "@/utils/modules";

export default {
  data() {
    return {
      toast: useToast(),
      options: [
        {label: 'Redondeo Arriba', index: 'UP'},
        {label: 'Redondeo Abajo', index: 'DOWN'}
      ],
      parameter: [],
      parameters: {},
      performances: [],
      selectedValue: {},
    };
  },
  methods: {
    async getData() {
      try {
        const response = await apiService.getData(API, 'parameters');
        this.parameters = response.data;
      } catch (error) {
        this.toast.add({severity: 'error', summary: 'Error', detail: error.response.data, life: 10000});
      }
    },
    save(){
      this.parameters.roundingType = this.selectedValue.index
      return apiService.update(API, 'parameters', this.parameters).then(
          () => {
            this.toast.add({severity: 'success', summary: 'Completado', detail: 'Registros Guardados!', life: 3000});
          },
          (error) => {
            this.toast.add({severity: 'error', summary: 'Error', detail: error.response.data, life: 10000});
          }
      );
    },
    calculate(){
      this.calculateApprovedPercentage();
      this.parameters.approved = parseFloat(this.parameters.approved.toFixed(this.parameters.decimals));
      this.parameters.color = this.knobStyle();
    },
    calculateApprovedPercentage() {
      const factor = Math.pow(10, this.parameters.decimals);
      const percentage = (this.parameters.approved / this.parameters.higher) * 100;
      const roundedPercentage = Math.round(percentage * factor) / factor;
      this.parameters.percentage = Math.round(roundedPercentage);
    },
    knobStyle() {
      const percentage =this.parameters.percentage;
      if (percentage < 30) {
        return '#FF1600';
      } else if (percentage >= 30 && percentage < 60) {
        return '#FFD700';
      } else {
        return '#10b981';
      }
    },
  },
  computed:{
    color(){
      return this.parameters.color;
    },
    calculatedStep() {
      return Math.pow(10, -this.parameters.decimals);
    },
  },
  async mounted() {
    await this.getData()
    this.selectedValue = {index:this.parameters.roundingType}
    this.calculateApprovedPercentage()
  }

};
</script>
<style scoped>
label {
  font-weight: bold;
}
</style>