<template>
  <div class="card">
    <Toolbar class="border-0">
      <template v-slot:start>
        <Chip>
          <i class="pi pi-tags" style="font-size: 2rem"></i>
          <span class="ml-2 font-medium">Curso: <strong>{{ assignment.course.name }}</strong></span>
        </Chip>
        <Chip>
          <i class="pi pi-book" style="font-size: 2rem"></i>
          <span class="ml-2 font-medium">Asignatura: <strong>{{ assignment.subject.name }}</strong></span>
        </Chip>
      </template>
      <template v-slot:end>
        <MeterGroup :value="value" :max="30"/>
      </template>
    </Toolbar>
  </div>
  <div class="card" v-if="loading">
    <SkeletonUploadComponent/>
  </div>
  <div class="card" v-else>
    <DataTable
        ref="dt"
        :value="finalScores"
        dataKey="id"
        :paginator="true"
        sortMode="multiple"
        removableSort
        :rows="25"
        stripedRows
        v-model:filters="filtersScore"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[25,30,35,40]"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
    >
      <template #header>
        <div class="formgrid grid justify-content-end">
                <span class="p-input-icon-left">
                  <i class="pi pi-search mr-2 mt-2"/>
                </span>
          <div>
            <InputText v-model="filtersScore['global'].value" placeholder="Search..."/>
          </div>
        </div>
      </template>
      <Column header="Alumno" field="student" body-style="min-width: 10rem;" :sortable="true" :filter="true"/>
      <Column v-for="comp in getComponents()" :key="comp.id" :field="`component_${comp.name}`" :sortable="true"
              :filter="true"
              style="min-width: 5rem;max-width:6rem">
        <template #header>
          <div class="header-content">
            <div class="header-name" style="text-align: center">{{ comp.name }}</div>
            <div class="header-percentage" style="text-align: center">{{ comp.percentage }}%</div>
          </div>
        </template>
      </Column>
      <Column header="Promedio Ponderado" field="average" :sortable="true" :filter="true"
              header-style="text-align: center; max-width:6rem"></Column>
      <Column header="Nota Minima" field="missingGrade" :sortable="true" :filter="true"></Column>
    </DataTable>
  </div>
</template>
<script>

import {API} from "@/utils/modules";
import {FilterMatchMode} from "@primevue/core/api";
import {useToast} from "primevue/usetoast";
import apiService from "@/service/api.service";
import SkeletonUploadComponent from "@/components/SkeletonUploadComponent.vue";

export default {
  name: "ScoresByAssignmentComponent",
  components: {SkeletonUploadComponent},
  props: {
    assignment: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      value: [
        {label: 'Alto', color: '#34d399', value: 16},
        {label: 'Medio', color: '#fbbf24', value: 6},
        {label: 'Bajo', color: '#dc2626', value: 8},
      ],
      finalScoreDialog: false,
      toast: useToast(),
      loading:false,
      filtersScore: {},
      finalScoreStudent: {},
      finalScores: [],
    };
  },
  methods: {
    getComponents() {
      return this.finalScores.length > 0 ? this.finalScores[0].periodScore.map(comp => ({
        name: comp.name,
        percentage: comp.percentage
      })) : [];
    },
    getData() {
      this.loading = true;
      this.finalScoreStudent.assignment = {'id': this.assignment.id};
      return apiService.getDataPost(API, 'final-scores/assignment', this.finalScoreStudent).then(
          (response) => {
            this.finalScores = response.data.map(score => {
              score.periodScore.forEach(comp => {
                score[`component_${comp.name}`] = comp.finalGrade;
              });
              return score;
            });
            this.loading = false;
          },
          (error) => {
            this.toast.add({severity: 'error', summary: 'Error', detail: error.response.data, life: 10000});
          }
      );
    },
    API() {
      return API
    },
    initFilters() {
      this.filtersScore = {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
      };
    }
  },
  mounted() {
    this.getData();
  },
  created() {
    this.initFilters();
  }
};
</script>