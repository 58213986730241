<template>
  <div>
    <GenericCrud :formType="'GenericForm'" title="Usuarios" path="users" property-name="username"
                 :columns-table="users()" :form-fields="fields" :dropdown-options="dropdownOptions()"
                 :module="security()"/>
  </div>
</template>
<script>

import {Users} from "@/entities/entities";
import GenericCrud from "@/components/GenericCrud.vue";
import {SECURITY} from "@/utils/modules";

export default {
  methods: {
    security() {
      return SECURITY
    },
    users() {
      return Users
    },
    dropdownOptions() {
      return {
        roles: [
          {name: 'ADMINISTRADOR', desc: 'ADMINISTRADOR'},
          {name: 'CONSULTOR', desc: 'CONSULTOR'},
        ]
      };
    },
  },
  components: {
    GenericCrud,
  },
  data() {
    return {
      fields: [
        {id: 'username', label: 'Nombre', model: 'username', type: 'text', required: true, props: {autofocus: true}},
        {id: 'email', label: 'Email', model: 'email', type: 'text', required: true, props: {autofocus: true}},
        {
          id: 'rol', label: 'Rol', options: 'roles', model: 'rol', type: 'dropdown', required: true,
          props: {
            placeholder: 'Seleccione un Rol',
            showClear: true,
            filter: true,
            optionLabel: 'name',
            optionValue: 'desc'
          }
        }
      ]
    };
  },
};
</script>
