<template>
  <ul class="layout-menu">
    <template v-for="(item, i) in model" :key="i">
      <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
      <li v-if="item.separator" class="menu-separator"></li>
    </template>
  </ul>
</template>

<script>
import AppMenuItem from './AppMenuItem.vue';

export default {
  components: {
    AppMenuItem
  },
  data() {
    return {
      model: [
        {
          label: 'Inicio',
          items: [{label: 'Panel de Control', icon: 'pi pi-fw pi-home', to: '/'}]
        },
        {
          label: 'Estudiantes',
          items: [
            {label: 'Acudientes', icon: 'pi pi-fw pi-users', to: '/guardians'},
            {label: 'Estudiantes', icon: 'pi pi-fw pi-id-card', to: '/students'},
            {label: 'Asignación Curso', icon: 'pi pi-fw pi-link', to: '/student-courses'},
          ]
        },
        {
          label: 'Administración Académica',
          items: [
            {label: 'Sedes', icon: 'pi pi-fw pi-building', to: '/campuses'},
            {label: 'Jornadas', icon: 'pi pi-fw pi-clock', to: '/shifts'},
            {label: 'Año Académico', icon: 'pi pi-fw pi-calendar', to: '/years'},
            {label: 'Periodos Académicos', icon: 'pi pi-fw pi-calendar-times', to: '/periods'},
            {label: 'Grados', icon: 'pi pi-fw pi-tag', to: '/grades'},
            {label: 'Cursos', icon: 'pi pi-fw pi-tags', to: '/courses'},
            {label: 'Asignaturas', icon: 'pi pi-fw pi-book', to: '/subjects'},
            {label: 'Componentes Calificación', icon: 'pi pi-fw pi-percentage', to: '/components'},
            {label: 'Desempeño Académico', icon: 'pi pi-fw pi-chart-line', to: '/performances'},
            {label: 'Parámetros', icon: 'pi pi-fw pi-cog', to: '/parameters'},
          ]
        },
        {
          label: 'Historia Académica',
          items: [
            {label: 'Conceptos Descriptivos', icon: 'pi pi-fw pi-bookmark', to: '/concept-descriptions'},
            {label: 'Evaluación', icon: 'pi pi-fw pi-check-square', to: '/academic-histories'},
          ]
        },
        {
          label: 'Gestión de Conducta',
          items: [
            {label: 'Observaciones', icon: 'pi pi-fw pi-comments', to: '/observations'},
            {label: 'Asistencias', icon: 'pi pi-fw pi-calendar-minus', to: '/attendances'},
            {label: 'Categorías de Observaciones', icon: 'pi pi-fw pi-sitemap', to: '/observation-categories'},
          ]
        },
        {
          label: 'Personal Educativo',
          items: [
            {label: 'Profesores', icon: 'pi pi-fw pi-user', to: '/teachers'},
            {label: 'Asignación Académica', icon: 'pi pi-fw pi-briefcase', to: '/assignments'},

          ]
        },
        {
          label: 'Horario Académico',
          items: [
            {label: 'Datos Básicos', icon: 'pi pi-fw pi-user', to: '/schedule/basic'},
            {label: 'Días', icon: 'pi pi-fw pi-calendar', to: '/schedule/basic/days'},
            {label: 'Horas', icon: 'pi pi-fw pi-clock', to: '/schedule/basic/hours'},
            {label: 'Horario Inactivo', icon: 'pi pi-fw pi-ban', to: '/schedule/basic/unavailabilityScheduleView'},
            {label: 'Horario', icon: 'pi pi-fw pi-briefcase', to: '/schedule/basic/schedule'},
          ]
        },
        {
          label: 'Administración de Usuarios',
          items: [
            {label: 'Usuarios', icon: 'pi pi-fw pi-wrench', to: '/users'},
          ]
        },
      ]
    };
  }
};
</script>
