import firebase from "firebase/compat/app";
import 'firebase/compat/auth'

const firebaseConfig = {
    apiKey: "AIzaSyBUNzZ70Qz82vKZrLKIumbXzxE0xR95Aw8",
    authDomain: "inventarios-digitalcodex.firebaseapp.com",
    projectId: "inventarios-digitalcodex",
    storageBucket: "inventarios-digitalcodex.appspot.com",
    messagingSenderId: "735606623922",
    appId: "1:735606623922:web:358d74e4513b0ceadfc3ab",
    measurementId: "G-EWFTCSLRY9"
};
firebase.initializeApp(firebaseConfig);
const auth = firebase.auth()

export {
    auth
}
