<template>
  <div>
    <GenericCrud :formType="'GenericForm'" title="Asignación de Cursos" path="student-courses" property-name="name"
                 :columns-table="columns()" :form-fields="fields"
                 :module="API()" :dropdown-options="dropdownOptions()"/>
  </div>
</template>
<script>

import {StudentCourses} from "@/entities/entities";
import GenericCrud from "@/components/GenericCrud.vue";
import {API} from "@/utils/modules";
import apiService from "@/service/api.service";

export default {
  components: {
    GenericCrud,
  },
  data() {
    return {
      students: [],
      courses: [],
      fields: [
        {
          id: 'student', label: 'Estudiante', options: 'students', model: 'student', type: 'dropdown', required: true,
          props: {
            placeholder: 'Seleccione un estudiante',
            showClear: true,
            filter: true,
            optionLabel: 'firstName'
          }
        }, {
          id: 'course', label: 'Curso', options: 'courses', model: 'course', type: 'dropdown', required: true,
          props: {
            placeholder: 'Seleccione un curso',
            showClear: true,
            filter: true,
            optionLabel: 'name'
          }
        }
      ]
    };
  },
  methods: {
    API() {
      return API
    },
    columns() {
      return StudentCourses
    },
    get(endpoint) {
      apiService.getData(API, endpoint)
          .then(response => {
            this[endpoint] = response.data;
          });
    },
    dropdownOptions() {
      return {
        students: this.students,
        courses: this.courses
      };
    },
  },
  mounted() {
    this.get('students');
    this.get('courses');
  }

};
</script>
