<template>
  <div class="card">
    <h1>Gestión de Horas</h1>
  </div>
  <div class="card">
    <InputNumber
        v-model="numHours"
        inputId="horizontal-buttons"
        showButtons
        buttonLayout="horizontal"
        @update:model-value="adjustHours"
    >
      <template #incrementbuttonicon>
        <span class="pi pi-plus"/>
      </template>
      <template #decrementbuttonicon>
        <span class="pi pi-minus"/>
      </template>
    </InputNumber>
  </div>
  <div class="card">
    <div v-for="(hour, index) in hours" :key="index" class="formgrid grid">
      <div class="mt-3 pt-2">
        <label class="font-bold" :for="'hour_' + index">Hora {{ index + 1 }}</label>
      </div>
      <div class="mt-3 ml-3">
        <InputText
            :class="{ 'p-invalid': submitted && !hour.name }"
            v-model="hour.name"
            :id="'hour_' + index"
            required
        ></InputText>
        <small class="p-invalid" v-if="submitted && !hour.name">El campo es requerido.</small>
      </div>
    </div>
    <Toolbar class="border-0">
      <template #center>
        <Button label="Guardar" icon="pi pi-check" severity="success" @click="validate"/>
      </template>
    </Toolbar>
  </div>
</template>

<script>
import { useToast } from "primevue/usetoast";
import apiService from "@/service/api.service";
import { API } from "@/utils/modules";

export default {
  name: "HourView",
  data() {
    return {
      toast: useToast(),
      submitted: false,
      numHours: 1,
      hours: [],
      defaultHours: ["Hora 1"],
    };
  },

  methods: {
    adjustHours() {
      if (this.numHours > this.hours.length) {
        for (let i = this.hours.length; i < this.numHours; i++) {
          const defaultHourName = this.defaultHours[i] || "";
          this.hours.push({id: null, name: defaultHourName});
        }
      } else if (this.numHours < this.hours.length) {
        this.hours = this.hours.slice(0, this.numHours);
      }
    },
    validate() {
      this.submitted = true;
      if (this.hours.slice(0, this.numHours).every((hour) => hour.name && hour.name.trim() !== "")) {
        this.save();
      } else {
        this.toast.add({severity: "error", summary: "Error", detail: "Todos los campos son obligatorios", life: 3000});
      }
    },
    save() {
      apiService.create(API, "hours", {'hours': this.hours}).then(
          () => {
            this.toast.add({severity: "success", summary: "Completado", detail: "Registros Guardados!", life: 3000});
          },
          (error) => {
            this.toast.add({severity: "error", summary: "Error", detail: error.response.data, life: 10000});
          }
      );
    },
    setHours(data) {
      if (data && data.hours.length > 0) {
        this.hours = data.hours;
        this.numHours = data.hours.length;
      } else {
        this.hours = [];
        this.numHours = 1;
        for (let i = 0; i < this.numHours; i++) {
          this.hours.push({id: null, name: this.defaultHours[i] || ""});
        }
      }
    },
    async getHours() {
      apiService.getData(API, "hours")
          .then((response) => {
            this.setHours(response.data);
          })
          .catch(() => {
          });
    }
  },
  mounted() {
    this.adjustHours();
    this.getHours();
  }
};
</script>