<template>
  <div>
    <GenericCrud :formType="'GenericForm'" title="Estudiantes" path="students" property-name="firstName"
                 :columns-table="columns()" :form-fields="fields"
                 :module="API()"/>
  </div>
</template>
<script>

import {Students} from "@/entities/entities";
import GenericCrud from "@/components/GenericCrud.vue";
import {API} from "@/utils/modules";

export default {
  methods: {
    API() {
      return API
    },
    columns() {
      return Students
    },
  },
  components: {
    GenericCrud,
  },
  data() {
    return {
      fields: [
        {id: 'firstName', label: 'Nombre', model: 'firstName', type: 'text', required: true, props: {autofocus: true}},
        {id: 'lastName', label: 'Nombre', model: 'lastName', type: 'text', required: true, props: {autofocus: true}},
      ]
    };
  },
};
</script>
