<template>
  <div>
    <GenericCrud :formType="'GenericForm'" title="Acudientes" path="guardians" property-name="firstName"
                 :columns-table="columns()" :form-fields="fields" :dropdown-options="dropdownOptions()"
                 :module="API()"/>
  </div>
</template>
<script>

import {Guardians} from "@/entities/entities";
import GenericCrud from "@/components/GenericCrud.vue";
import {API} from "@/utils/modules";

export default {
  methods: {
    API() {
      return API
    },
    columns() {
      return Guardians
    },
    dropdownOptions() {
      return {
        roles: [
          {name: 'P1', desc: 'ADMINISTRADOR'},
          {name: 'P2', desc: 'CONSULTOR'},
        ]
      };
    },
  },
  components: {
    GenericCrud,
  },
  data() {
    return {
      fields: [
        {id: 'firstName', label: 'Nombre', model: 'firstName', type: 'text', required: true, props: {autofocus: true}},
        {id: 'lastName', label: 'Nombre', model: 'lastName', type: 'text', required: true, props: {autofocus: true}},
        {
          id: 'relationship',
          label: 'Relación',
          options: 'roles',
          model: 'relationship',
          type: 'dropdown',
          required: false,
          props: {
            placeholder: 'Seleccione un Parentesco',
            showClear: true,
            filter: true,
            optionLabel: 'name',
            optionValue: 'desc'
          }
        }
      ]
    };
  },
};
</script>
