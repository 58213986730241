<script>
import {auth} from '@/utils/firebase'

export default {
  unmounted() {
    auth().signOut().then(() => {
      console.log('Sesión cerrada al cerrar la pestaña o el navegador');
    }).catch((error) => {
      console.error('Error al cerrar sesión:', error);
    });
  },
};
</script>

<template>
  <router-view/>
</template>
