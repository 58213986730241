<template>
  <div class="card">
    <h1>Gestión de Conceptos Descriptivos</h1>
  </div>
  <div class="card" v-if="!editing">
    <DataTable
        ref="dt"
        :value="assignments"
        v-model:selection="selected"
        dataKey="id"
        :paginator="true"
        sortMode="multiple"
        removableSort
        :rows="10"
        stripedRows
        v-model:filters="filtersAssignment"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
    >
      <template #header>
        <div class="flex flex-row justify-content-end">
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search mr-2"/>
                                <InputText v-model="filtersAssignment['global'].value" placeholder="Search..."/>
                            </span>
        </div>
      </template>
      <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header" :sortable="true">
        <template #body="{ data, field }">
          <div>
            {{ formatNestedValue(getNestedValue(data, field)) }}
          </div>
        </template>
      </Column>
      <Column headerStyle="min-width:10rem;" bodyStyle="text-align: right;">
        <template #body="slotProps">
          <Button icon="pi pi-check-square" v-tooltip="'Crear Conceptos'" class="p-button-rounded p-button-success mt-2"
                  @click="createConcepts(slotProps.data)"/>
        </template>
      </Column>
    </DataTable>
  </div>
  <div v-else>
    <div class="card">
      <Toolbar class="border-0">
        <template v-slot:start>
          <Chip class="h-2.5rem mr-3">
            <i class="pi pi-book" style="font-size: 2rem"></i>
            <span class="ml-2 font-medium">Materia: <strong>{{ assignment.subject.name }}</strong></span>
          </Chip>
          <Chip class="h-2.5rem mr-3">
            <i class="pi pi-tags" style="font-size: 2rem"></i>
            <span class="ml-2 font-medium">Curso: <strong>{{ assignment.course.name }}</strong></span>
          </Chip>
        </template>
        <template v-slot:end>
          <div class="sm:mt-3 xl:mt-0">
            <Button icon="pi pi-arrow-circle-left" class="p-button-secondary mr-3" label="Atrás"
                    @click="cancel"></Button>
          </div>
        </template>
      </Toolbar>
    </div>
    <div class="card">
      <Toolbar class="mb-4">
        <template v-slot:start>
          <div class="my-2">
            <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew"/>
            <Button label="Eliminar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"
                    :disabled="!selected || !selected.length"/>
          </div>
        </template>
      </Toolbar>
      <DataTable :value="concepts"
                 v-model:selection="selected"
                 dataKey="id"
                 :paginator="true"
                 sortMode="multiple"
                 removableSort
                 :rows="10"
                 stripedRows
                 :filters="filters"
                 paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                 :rowsPerPageOptions="[5, 10, 25]"
                 currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros">
        <template #header>
          <div class="flex flex-row justify-content-end">
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search mr-2"/>
                                <InputText v-model="filters['global'].value" placeholder="Search..."/>
                            </span>
          </div>
        </template>
        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
        <Column header="Código" field="code"></Column>
        <Column header="Descripción" field="description"></Column>
        <Column headerStyle="min-width:10rem;" bodyStyle="text-align: right;">
          <template #body="slotProps">
            <Button icon="pi pi-pencil" class="p-button-rounded mr-2" severity="success"
                    @click="updateRecordSelected(slotProps.data)"/>
            <Button icon="pi pi-trash" class="p-button-rounded mt-2" severity="warn"
                    @click="confirmDelete(slotProps.data)"/>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
  <component :is="genericForm"
             :edit="edit"
             title="Crear concepto académico"
             :active="conceptDialog"
             @update:active="conceptDialog = $event"
             :object="concept"
             @record-created="onRecordCreated"
             @record-updated="onRecordUpdated"
             :fields="fields"
  />
  <Dialog v-model:visible="deleteDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
      <span v-if="concept">¿Esta seguro de eliminar el registro <b>{{ concept.code }}</b>?</span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDialog = false"/>
      <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteRecord"/>
    </template>
  </Dialog>
  <Dialog v-model:visible="deleteListDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
      <span v-if="concept">¿Esta Seguro de eliminar los registros seleccionados?</span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteListDialog = false"/>
      <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteSelected"/>
    </template>
  </Dialog>
</template>

<script>
import {useStore} from "vuex";
import {getData, getNestedValue, postData} from "@/utils/funtions";
import {useToast} from "primevue/usetoast";
import {FilterMatchMode} from "@primevue/core/api";
import apiService from "@/service/api.service";
import {API} from "@/utils/modules";
import genericForm from "@/components/forms/GenericForm.vue";

export default {
  computed: {
    genericForm() {
      return genericForm
    }
  },
  data() {
    return {
      toast: useToast(),
      edit: false,
      editing: false,
      conceptDialog: false,
      deleteListDialog: false,
      deleteDialog: false,
      assignments: [],
      selected: [],
      concepts: [],
      period: {},
      fields: [{
        label: 'Código',
        model: 'code',
        type: 'text',
        required: true,
        props: {autofocus: true}
      },
        {label: 'Descripción', model: 'description', type: 'text', required: true, props: {autofocus: true}},
      ],
      filters: {},
      assignment: {},
      concept: {},
      filtersAssignment: {},
      columns: [{field: 'subject.name', header: 'Asignatura'}, {field: 'course.name', header: 'Curso'}],
    };
  },
  methods: {
    getNestedValue,
    updateRecordSelected(edit) {
      this.concept = {...edit};
      this.edit = true;
      this.conceptDialog = true;
    },
    deleteRecord() {
      this.delete()
    },
    delete() {
      return apiService.delete(API, 'concept-descriptions', this.concept).then(
          (response) => {
            this.deleteDialog = false;
            this.concept = {};
            this.toast.add({severity: 'success', summary: 'Completado', detail: response.data, life: 3000});
            getData('concepts', 'concept-descriptions', this)
          },
          (error) => {
            this.toast.add({severity: 'error', summary: 'Completado', detail: error.response.data, life: 10000});
            this.deleteDialog = false;
          }
      );
    },
    onRecordCreated(newRecord) {
      this.concept = newRecord;
      this.create();
    },
    deleteSelected() {
      this.deleteList()
    },
    deleteList() {
      return apiService.deleteList(API, 'concept-descriptions', this.selected).then(
          (response) => {
            this.concepts = this.concepts.filter((val) => !this.selected.includes(val));
            this.deleteListDialog = false;
            this.selected = null;
            this.toast.add({severity: 'success', summary: 'Completado', detail: response.data, life: 3000});
          },
          (error) => {
            this.toast.add({
              severity: 'error',
              summary: 'Error al eliminar registro',
              detail: error.response.data,
              life: 10000
            });
          }
      );
    },
    create() {
      this.concept.assignment = this.assignment;
      this.concept.period = this.period;
      return apiService.create(API, 'concept-descriptions', this.concept).then(
          () => {
            this.toast.add({severity: 'success', summary: 'Completado', detail: 'Registro Creado!', life: 3000});
            this.conceptDialog = false;
            this.concept = {};
            postData('concepts', 'concept-descriptions/assignment', this.assignment)
          },
          (error) => {
            this.toast.add({severity: 'error', summary: 'Successful', detail: error.response.data, life: 10000});
          }
      );
    },
    async update() {
      this.concept.assignment = this.assignment;
      this.concept.period = this.period;
      return apiService.update(API, 'concept-descriptions', this.concept).then(
          () => {
            this.toast.add({severity: 'success', summary: 'Completado', detail: 'Registro Actualizado!', life: 3000});
            this.conceptDialog = false;
            this.concept = {};
            postData('concepts', 'concept-descriptions/assignment', this.assignment)
          },
          (error) => {
            this.toast.add({severity: 'error', summary: 'Successful', detail: error.response.data, life: 10000});
          }
      );
    },
    onRecordUpdated(updated) {
      this.concept = updated;
      this.update();
    },
    hideDialog(option) {
      if (option === 'create') {
        this.conceptDialog = false;
      }
    },
    openNew() {
      this.concept = {};
      this.conceptDialog = true;
      this.edit = false;
    },
    confirmDeleteSelected() {
      this.deleteListDialog = true;
    },
    cancel() {
      this.editing = false;
      this.concepts=[];
    },
    formatNestedValue(value) {
      if (Array.isArray(value)) {
        return value.flat().filter(v => v !== undefined).join(', ');
      }
      return value;
    },
    getUser() {
      const store = useStore();
      return store.getters.getCurrentUser.email;
    },
    confirmDelete(data) {
      this.concept = data;
      this.deleteDialog = true;
    },
    createConcepts(data) {
      this.assignment = data;
      postData('concepts', 'concept-descriptions/assignment', this.assignment,this)
      this.editing = true;
    },
    initFilters() {
      this.filters = {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS}
      };
      this.filtersAssignment = {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS}
      };
    }
  },
  mounted() {
    getData('assignments', 'assignments/email?email=' + this.getUser(), this);
    getData('period', 'periods/active', this)
  },
  created() {
    this.initFilters();
  }
};
</script>
