<template>
  <div>
    <GenericCrud :formType="'GenericForm'" title="Jornadas" path="shifts" property-name="name"
                 :columns-table="columns()" :form-fields="fields"
                 :module="API()"/>
  </div>
</template>
<script>

import {Shifts} from "@/entities/entities";
import GenericCrud from "@/components/GenericCrud.vue";
import {API} from "@/utils/modules";

export default {
  methods: {
    API() {
      return API
    },
    columns() {
      return Shifts
    }
  },
  components: {
    GenericCrud,
  },
  data() {
    return {
      fields: [
        {id: 'name', label: 'Nombre', model: 'name', type: 'text', required: true, props: {autofocus: true}},
        {id: 'startTime', label: 'Hora de inicio', model: 'startTime', type: 'time',timeOnly:true, required: false, props: {autofocus: true}},
        {id: 'finalTime', label: 'Hora final', model: 'finalTime', type: 'time',timeOnly:true, required: false, props: {autofocus: true}},
      ]
    };
  },
};
</script>
