import apiService from "@/service/api.service";
import {API} from "@/utils/modules";
import {useStore} from "vuex";

function getData(object, endpoint, context) {
    context.loading = true;
    apiService.getData(API, endpoint)
        .then(response => {
            context[object] = response.data;
            context.loading = false;
        }).catch(error => {
        context.loading = false;
        context.toast.add({severity: 'warn', summary: 'Información', detail: error.response.data, life: 10000});
    });
}

function postData(object, endpoint, record, context) {
    apiService.getDataPost(API, endpoint, record)
        .then(response => {
            context[object] = response.data;
        }).catch(error => {
        context.toast.add({severity: 'warn', summary: 'Información', detail: error.response.data, life: 10000});
    });
}

function getUser() {
    const store = useStore();
    return store.getters.getCurrentUser.email;
}

function getNestedValue(obj, path) {
    return path.split('.').reduce((acc, part) => {
        if (Array.isArray(acc)) {
            return acc.map(item => item && item[part]);
        } else {
            return acc && acc[part];
        }
    }, obj);
}

function sortByName(array) {
    return array.sort((a, b) => {
        const nameA = a.name.split("-").map(part => parseInt(part, 10) || part);
        const nameB = b.name.split("-").map(part => parseInt(part, 10) || part);

        for (let i = 0; i < Math.max(nameA.length, nameB.length); i++) {
            if (nameA[i] !== nameB[i]) {
                if (nameA[i] === undefined) {
                    return -1;
                }
                if (nameB[i] === undefined) {
                    return 1;
                }
                if (typeof nameA[i] === "number" && typeof nameB[i] === "string") {
                    return -1;
                }
                if (typeof nameA[i] === "string" && typeof nameB[i] === "number") {
                    return 1;
                }
                return nameA[i] < nameB[i] ? -1 : 1;
            }
        }
        return 0;
    });
}
function sortByAttribute(array, attribute) {
    return array.sort((a, b) => {
        const attrA = getNestedValue(a, attribute).split("-").map(part => parseInt(part, 10) || part);
        const attrB = getNestedValue(b, attribute).split("-").map(part => parseInt(part, 10) || part);

        for (let i = 0; i < Math.max(attrA.length, attrB.length); i++) {
            if (attrA[i] !== attrB[i]) {
                if (attrA[i] === undefined) {
                    return -1;
                }
                if (attrB[i] === undefined) {
                    return 1;
                }
                if (typeof attrA[i] === "number" && typeof attrB[i] === "string") {
                    return -1;
                }
                if (typeof attrA[i] === "string" && typeof attrB[i] === "number") {
                    return 1;
                }
                return attrA[i] < attrB[i] ? -1 : 1;
            }
        }
        return 0;
    });
}

function generateCombinations(n, maxLength) {
    return generateCombinationsHelper(n, maxLength);
}
function generateCombinationsHelper(n, maxLength) {
    const result = [];
    const helper = (n, combination, maxLength) => {
        if (n === 0) {
            result.push([...combination]);
            return;
        }
        if (combination.length === maxLength) {
            return;
        }
        const start = combination.length === 0 ? n : combination[combination.length - 1];
        for (let i = start; i > 0; i--) {
            if (n - i >= 0) {
                combination.push(i);
                helper(n - i, combination, maxLength);
                combination.pop();
            }
        }
    };
    helper(n, [], maxLength);
    return result;
}


export {
    getData,
    getUser,
    postData,
    getNestedValue,
    sortByName,
    sortByAttribute,
    generateCombinations,
};