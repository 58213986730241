<template>
  <div>
    <GenericCrud :formType="'GenericForm'" title="Cursos" path="courses" property-name="name"
                 :columns-table="columns()" :form-fields="fields"
                 :module="API()" :dropdown-options="dropdownOptions()"/>
  </div>
</template>
<script>

import {Courses} from "@/entities/entities";
import GenericCrud from "@/components/GenericCrud.vue";
import {API} from "@/utils/modules";
import apiService from "@/service/api.service";

export default {
  components: {
    GenericCrud,
  },
  data() {
    return {
      years: [],
      shifts: [],
      campuses: [],
      grades: [],
      fields: [
        {id: 'name', label: 'Nombre', model: 'name', type: 'text', required: true, props: {autofocus: true}},
        {
          id: 'credits',
          label: 'Créditos',
          model: 'credits',
          type: 'number',
          required: true,
          props: {autofocus: true, max: 100}
        },
        {
          id: 'shift', label: 'Jornada', options: 'shifts', model: 'shift', type: 'dropdown', required: true,
          props: {
            placeholder: 'Seleccione una jornada',
            showClear: true,
            filter: true,
            optionLabel: 'name'
          }
        }, {
          id: 'campus', label: 'Sede', options: 'campuses', model: 'campus', type: 'dropdown', required: true,
          props: {
            placeholder: 'Seleccione una sede',
            showClear: true,
            filter: true,
            optionLabel: 'name'
          }
        }, {
          id: 'grade', label: 'Grado', options: 'grades', model: 'grade', type: 'dropdown', required: true,
          props: {
            placeholder: 'Seleccione un grado',
            showClear: true,
            filter: true,
            optionLabel: 'name'
          }
        }, {
          id: 'year', label: 'Año Vigencia', options: 'years', model: 'year', type: 'dropdown', required: true,
          props: {
            placeholder: 'Seleccione un año de vigencia',
            showClear: true,
            filter: true,
            optionLabel: 'name'
          }
        }
      ]
    };
  },
  methods: {
    API() {
      return API
    },
    columns() {
      return Courses
    },
    get(endpoint) {
      apiService.getData(API, endpoint)
          .then(response => {
            this[endpoint] = response.data;
          });
    },
    dropdownOptions() {
      return {
        shifts: this.shifts,
        campuses: this.campuses,
        grades: this.grades,
        years: this.years

      };
    },
  },
  mounted() {
    this.get('shifts');
    this.get('grades');
    this.get('years');
    this.get('campuses');
  }

};
</script>
