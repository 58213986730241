<template>
  <div class="card">
    <h1>Gestión de Horario</h1>
  </div>
  <div class="card">
    <InputNumber
        v-model="numDays"
        inputId="horizontal-buttons"
        showButtons
        buttonLayout="horizontal"
        @update:model-value="adjustDays"
    >
      <template #incrementbuttonicon>
        <span class="pi pi-plus"/>
      </template>
      <template #decrementbuttonicon>
        <span class="pi pi-minus"/>
      </template>
    </InputNumber>
  </div>
  <div class="card">
    <div v-for="(day, index) in days" :key="index" class="formgrid grid">
      <div class="mt-3 pt-2">
        <label class="font-bold" :for="'day_' + index">Dia {{ index + 1 }}</label>
      </div>
      <div class="mt-3 ml-3">
        <InputText
            :class="{ 'p-invalid': submitted && !day.name }"
            v-model="day.name"
            :id="'day_' + index"
            required
        ></InputText>
        <small class="p-invalid" v-if="submitted && !day.name">el campo es requerido.</small>
      </div>
    </div>
    <Toolbar class="border-0">
      <template #center>
        <Button label="Guardar" icon="pi pi-check" severity="success" @click="validate"/>
      </template>
    </Toolbar>
  </div>
</template>

<script>
import apiService from "@/service/api.service";
import {API} from "@/utils/modules";
import {useToast} from "primevue/usetoast";

export default {
  name: "DaysView",
  data() {
    return {
      toast: useToast(),
      submitted: false,
      numDays: 5,
      days: [],
      defaultDays: ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"],
    };
  },
  methods: {
    adjustDays() {
      if (this.numDays > this.days.length) {
        for (let i = this.days.length; i < this.numDays; i++) {
          const defaultDayName = this.defaultDays[i] || "";
          this.days.push({id: null, name: defaultDayName});
        }
      } else if (this.numDays < this.days.length) {
        this.days = this.days.slice(0, this.numDays);
      }
    },
    validate() {
      this.submitted = true;
      if (this.days.slice(0, this.numDays).every((day) => day.name && day.name.trim() !== "")) {
        this.save();
      } else {
        this.toast.add({severity: "error", summary: "Error", detail: "Todos los campos son obligatorios", life: 3000});
      }
    },
    save() {
      apiService.create(API, "days", {'days': this.days}).then(
          () => {
            this.toast.add({severity: "success", summary: "Completado", detail: "Registros Guardados!", life: 3000});
          },
          (error) => {
            this.toast.add({severity: "error", summary: "Error", detail: error.response.data, life: 10000});
          }
      );
    },
    setDays(data) {
      if (data && data.days.length > 0) {
        this.days = data.days;
        this.numDays = data.days.length;
      } else {
        this.days = [];
        this.numDays = 5;
        for (let i = 0; i < this.numDays; i++) {
          this.days.push({id: null, name: this.defaultDays[i] || ""});
        }
      }
    },
    async getDays() {
      apiService.getData(API, "days")
          .then((response) => {
            this.setDays(response.data);
          })
          .catch(() => {
          });
    }
  },
  mounted() {
    this.adjustDays();
    this.getDays();
  }
};
</script>