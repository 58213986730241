<template>
  <div class="card">
    <Toolbar class="border-0">
      <template v-slot:start>
        <Chip class="h-2.5rem mr-3">
          <i class="pi pi-calendar-times" style="font-size: 2rem"></i>
          <span class="ml-2 font-medium">Periodo Académico: <strong>{{ period.name }}</strong></span>
        </Chip>
        <Chip class="h-2.5rem mr-3">
          <i class="pi pi-tags" style="font-size: 2rem"></i>
          <span class="ml-2 font-medium">Curso: <strong>{{ assignment.course.name }}</strong></span>
        </Chip>
        <Chip class="h-2.5rem mr-3">
          <i class="pi pi-book" style="font-size: 2rem"></i>
          <span class="ml-2 font-medium">Asignatura: <strong>{{ assignment.subject.name }}</strong></span>
        </Chip>
      </template>
      <template v-slot:end>
        <div class="sm:mt-3 xl:mt-0">
          <Button icon="pi pi-arrow-circle-left" class="p-button-secondary mr-3" label="Atrás" @click="cancel"></Button>
          <Button icon="pi pi-save" class="p-button-primary" label="Guardar" @click="save"></Button>
        </div>
      </template>
    </Toolbar>
  </div>
  <div class="card" v-if="loading">
    <SkeletonUploadComponent/>
  </div>
  <div class="card" v-else>
    <DataTable
        ref="dt"
        :value="students"
        v-model:selection="selectedStudent"
        dataKey="id"
        :paginator="true"
        sortMode="multiple"
        removableSort
        :rows="25"
        stripedRows
        :filters="filtersStudent"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[25,30,35,40]"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros">
      <template #header>
        <div class="flex flex-row justify-content-end">
                  <span class="block mt-2 md:mt-0 p-input-icon-left">
                    <i class="pi pi-search mr-2"/>
                    <InputText v-model="filtersStudent['global'].value" placeholder="Search..."/>
                  </span>
        </div>
      </template>
      <Column header="Alumno" field="student" :sortable="true" :filter="true"/>
      <Column v-for="comp in getComponents()" :key="comp.id" :field="`component_${comp.id}`" :sortable="true"
              :filter="true"
              style="max-width: 3rem;">
        <template #header>
          {{ getTitle(comp.id) }}%
        </template>
        <template #body="{ data }">
          <InputNumber class="p-inputnumber-input" v-model="data.components.find(c => c.id === comp.id).value"
                       @keydown="calculateFinalGrade(data)"
                       :min="parameters.less" :max="parameters.higher" :max-fraction-digits="parameters.decimals" :step="getStepSize"
                       :minFractionDigits="1">
          </InputNumber>
        </template>
      </Column>
      <Column header="Nota" field="finalGrade" :sortable="true" style="max-width: 3rem;">
        <template #body="{ data }">
          <div class="final-grade-column"><span>{{ roundToDecimals(data.finalGrade, decimals) }}</span></div>
        </template>
      </Column>
      <Column header="Desempeño" field="performance" :sortable="true" style="max-width: 5rem;">
        <template #body="{ data }">
          <div v-if="(performance = evaluatePerformance(data.finalGrade))" class="performance-column"
               :style="getPerformanceClass(data.finalGrade)">
            <span>{{ performance }}</span>
          </div>
        </template>
      </Column>
      <Column header="Justificadas" field="justified" :sortable="true" style="min-width: 4rem;max-width: 5rem;">
        <template #body="{ data }">
            <InputNumber v-model="data.justified"
                         :maxFractionDigits="0" >
            </InputNumber>
        </template>
      </Column>
      <Column header="Injustificadas" field="unjustified" :sortable="true" :autofocus="false"
              style="min-width: 4rem;max-width: 4rem;">
        <template #body="{ data }">
            <InputNumber v-model="data.unjustified"
                         :maxFractionDigits="0">
            </InputNumber>
        </template>
      </Column>
      <Column body-style="text-align: right;">
        <template #body="{data}">
          <Button icon="pi pi-eye" v-tooltip="'Ver Historia'" class="p-button-rounded p-button-info mr-2"
                  @click="view(data)"/>
          <Button icon="pi pi-bookmark" v-tooltip="'Agregar conceptos descriptivos'"
                  class="p-button-rounded p-button-success mt-2"
                  @click="addConcept(data)"/>
        </template>
      </Column>
    </DataTable>
  </div>
  <Dialog v-model:visible="conceptDialog" :style="{ width: '80%' }" header="Agregar conceptos descriptivos"
          :modal="true"
          class="p-fluid">
    <ConceptDescriptionComponent
        ref="conceptDescription"
        :selectedConcepts="conceptsSelected"
        :formType="'GenericForm'" path="concept-descriptions" property-name="code"
        :columns-table="columns()" :module="API()"
        :assignment="assignment"
        :period="period"
        :student="selected.student"
    />
    <template #footer>
      <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialogConcept"/>
      <Button label="Confirmar" icon="pi pi-check" class="p-button-text" @click="confirm"/>
    </template>
  </Dialog>
  <Dialog v-model:visible="finalScoreDialog" :style="{ width: '80%' }" header="Visualizar historia académica"
          :modal="true"
          class="p-fluid">
    <FinalScoreStudentComponent :final-scores="finalScores" :student="selected.student" :period="period"
                                :assignment="assignment"/>
    <template #footer>
      <Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="hideDialog('finalScore')"/>
    </template>
  </Dialog>
  <Dialog
      v-model:visible="uploading"
      :modal="true"
      :blockScroll="true"
      :closable="false"
      :dismissableMask="false"
  >
    <div class="full-screen-spinner">
      <ProgressSpinner></ProgressSpinner>
    </div>
  </Dialog>
</template>

<script>
import apiService from "@/service/api.service";
import {API} from "@/utils/modules";
import {FilterMatchMode} from "@primevue/core/api";
import ConceptDescriptionComponent from "@/components/ConceptDescriptionComponent.vue";
import {Concepts} from "@/entities/entities";
import {useToast} from "primevue/usetoast";
import {getData} from "@/utils/funtions";
import FinalScoreStudentComponent from "@/components/FinalScoreStudentComponent.vue";
import SkeletonUploadComponent from "@/components/SkeletonUploadComponent.vue";

export default {
  name: "EvaluateVIew",
  components: {SkeletonUploadComponent, FinalScoreStudentComponent, ConceptDescriptionComponent},
  props: {
    assignment: Object,
    period: Object,
    active: Boolean
  },
  data() {
    return {
      parameters:{},
      decimals: 1,
      uploading: false,
      loading: false,
      toast: useToast(),
      conceptDialog: false,
      finalScoreDialog: false,
      students: [],
      studentsCreate: [],
      request: {},
      selectedStudent: [],
      selected: {},
      filtersStudent: {},
      assignmentConcepts: [],
      concept: {},
      disableConcept: true,
      components: [],
      conceptsSelected: [],
      finalScores: [],
      finalScoreStudent: {},
      performances: [],
      colorMap: [
        {background: '#c3e6cb', color: '#155724'},  // Verde más oscuro
        {background: '#d4edda', color: '#155724'}, // Verde claro
        {background: '#fff3cd', color: '#856404'}, // Amarillo claro
        {background: '#ffeeba', color: '#856404'}, // Amarillo mas claro
        {background: '#f5c6cb', color: '#721c24'}, // Rojo más claro
        {background: '#f8d7da', color: '#721c24'}, // Rojo claro
      ],
    }
  },
  methods: {
    API() {
      return API
    },
    columns() {
      return Concepts;
    },
    getComponents() {
      return this.students.length > 0 ? this.students[0].components : [];
    },
    hideDialog(option) {
      if (option === 'concept')
        this.conceptDialog = false;
      else {
        this.finalScoreDialog = false;
      }
    },
    hideDialogConcept() {
      this.conceptDialog = false;
    },
    addConcept(data) {
      this.conceptDialog = true;
      this.selected = data;
      const student = this.students.find(s => s.id === data.id);
      if (student && student.concepts) {
        this.conceptsSelected = student.concepts;
      } else {
        this.conceptsSelected = [];
      }
    },
    evaluatePerformance(finalGrade) {
      if (isNaN(finalGrade)) {
        return 'No Evaluado';
      }
      finalGrade = this.roundToDecimals(finalGrade, this.decimals)
      const orderedPerformances = this.performances.sort((a, b) => b.finalRange - a.finalRange);
      for (let performance of orderedPerformances) {
        if (finalGrade >= performance.initialRange && finalGrade <= performance.finalRange) {
          return performance.name;
        }
      }
      return 'No Evaluado';
    },
    getVisibleLevels(totalLevels) {
      const visibilityPatterns = {
        6: [0, 1, 2, 3, 4, 5],
        5: [0, 1, 2, 4, 5],
        4: [0, 1, 3, 5],
        3: [0, 2, 4],
        2: [0, 5],
      };
      return visibilityPatterns[totalLevels] || [];
    },
    getPerformanceClass(finalGrade) {
      finalGrade = this.roundToDecimals(finalGrade, this.decimals)
      const orderedPerformances = this.performances.sort((a, b) => b.finalRange - a.finalRange);
      const visibleLevels = this.getVisibleLevels(orderedPerformances.length);
      for (let performance of orderedPerformances) {
        if (finalGrade >= performance.initialRange && finalGrade <= performance.finalRange) {
          const index = orderedPerformances.indexOf(performance);
          const adjustedIndex = visibleLevels[index];
          if (adjustedIndex !== -1) {
            const style = this.colorMap[adjustedIndex] || {background: '#ffffff', color: '#000000'};
            return {
              backgroundColor: style.background,
              color: style.color
            };
          }
        }
      }
      return {backgroundColor: '#ffffff', color: '#000000'};
    },
    cancel() {
      this.$emit('update:active', false);
    },
    view(data) {
      this.selected = data;
      this.finalScoreStudent.student = data.id;
      this.finalScoreStudent.assignment = this.assignment;
      return apiService.getDataPost(API, 'final-scores/student', this.finalScoreStudent).then(
          (response) => {
            this.finalScores = response.data
            this.finalScoreDialog = true;
          },
          (error) => {
            this.toast.add({severity: 'error', summary: 'Error', detail: error.response.data, life: 10000});
          }
      );
    },
    async getParameters() {
      try {
        const response = await apiService.getData(API, 'parameters');
        this.parameters = response.data;
      } catch (error) {
        this.toast.add({severity: 'error', summary: 'Error', detail: error.response.data, life: 10000});
      }
    },
    getData() {
      this.loading = true;
      this.request.period = this.period;
      this.request.assignment = this.assignment;
      return apiService.getDataPost(API, 'academic-histories/response', this.request).then(
          (response) => {
            this.formDialog = false;
            this.loading = false;
            this.record = {};
            this.students = response.data.student;
            this.students.forEach(student => {
              student.components.forEach(comp => {
                student[`component_${comp.id}`] = comp.value;
              });
              this.calculateFinalGrade(student);
            });
          },
          (error) => {
            this.toast.add({severity: 'error', summary: 'Error', detail: error.response.data, life: 10000});
          }
      );
    },
    save() {
      this.studentsCreate = this.students.map(student => {
        const transformedConcepts = Array.isArray(student.concepts)
            ? student.concepts.map(concept => concept) : [];
        return {
          ...student,
          concepts: transformedConcepts
        };
      });
      this.uploading = true;
      this.request.student = this.studentsCreate;
      this.request.assignment = this.assignment;
      this.request.period = this.period;
      return apiService.create(API, 'academic-histories/request', this.request).then(
          () => {
            this.uploading = false;
            this.toast.add({severity: 'success', summary: 'Completado', detail: 'Registros Guardados!', life: 3000});
            this.formDialog = false;
            this.record = {};
          },
          (error) => {
            this.toast.add({severity: 'error', summary: 'Error', detail: error.response.data, life: 10000});
          }
      );
    },
    confirm() {
      if (this.$refs.conceptDescription) {
        this.conceptsSelected = this.$refs.conceptDescription.getSelectedItems();
      }
      if (this.selected && this.selected.id) {
        const student = this.students.find(s => s.id === this.selected.id);
        if (student) {
          const transformedConcepts = Array.isArray(this.conceptsSelected)
              ? this.conceptsSelected.map(concept => concept.id) : [];
          student.concepts = transformedConcepts.length > 0 ? transformedConcepts : null;
        }
        this.hideDialogConcept();
      }
      this.conceptsSelected = [];
    },
    roundToDecimals(number, decimals) {
      if (number === undefined) {
        return (0).toFixed(decimals);
      } else {
        const factor = Math.pow(10, decimals);
        let rounded;
        if (this.parameters.roundingType === 'UP') {
          rounded = Math.round(number * factor) / factor;
        } else if (this.parameters.roundingType === 'DOWN') {
          const multiplied = number * factor;
          rounded = (Math.floor(multiplied) + (multiplied % 1 > 0.5 ? 1 : 0)) / factor;
        } else {
          rounded = Math.round(number * factor) / factor;
        }
        return rounded.toFixed(decimals);
      }
    },
    getTitle(data) {
      const foundComponent = this.components.filter(c => c.id === data);
      if (foundComponent) {
        const weight = foundComponent[0].weight;
        if (weight !== undefined)
          return weight;
      }
    },
    calculateFinalGrade(student) {
      let totalGrade = 0;
      student.components.forEach(component => {
        const foundComponent = this.components.filter(c => c.id === component.id);
        if (foundComponent) {
          const weight = foundComponent[0].weight;
          if (weight !== undefined) {
            totalGrade += component.value * (weight / 100);
          }
        }
      });
      student.finalGrade = this.roundToDecimals(totalGrade, this.decimals);
      student.performance = this.evaluatePerformance(totalGrade);
      totalGrade = 0;

    }
  },
  computed:{
    getStepSize() {
      return Math.pow(10, -this.decimals);
    },
  },
  async mounted() {
    getData('components', 'components', this)
    getData('performances', 'performances', this)
    await this.getParameters();
    this.decimals = this.parameters.decimals;
    this.getData();
  },
  created() {
    this.filtersStudent = {
      global: {value: null, matchMode: FilterMatchMode.CONTAINS}
    };
  }
}
</script>

<style scoped>

::v-deep(.p-inputnumber-input) {
  width: 4rem !important;
  text-align : center;
}
.performance-column {
  padding: 0.5rem;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
}

.final-grade-column span {
  font-weight: bold;
  font-size: 1.5rem;
  text-align: left;
}

.full-screen-dialog {
  width: 100vw !important;
  height: 100vh !important;
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}

.full-screen-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>

