<script>
export default {
  name: "SkeletonUploadComponent",
  data() {
    return {
      upload: new Array(4),
      columnsSkeleton: [{header: 'Alumno', size: '20%'}, {header: 'Nota', size: '15%'}, {
        header: 'Desempeño',
        size: '15%'
      }, {header: 'Justificadas', size: '15%'}, {header: 'Injustificadas', size: '15%'}],
    }
  }
}
</script>

<template>
  <DataTable :value="upload">
    <Column v-for="col of columnsSkeleton" :key="col.header" :header="col.header" :style="{width: col.size}">
      <template #body>
        <Skeleton/>
      </template>
    </Column>
  </DataTable>
</template>

<style scoped lang="scss">

</style>