<template>
  <div class="card">
    <h1>Generar Horario</h1>
  </div>

  <div class="card">
    <DataTable :value="tableData">
      <Column field="hour" header="Hora">
        <template #body="{ data }">
          <div style="min-width: 4rem"><strong>{{ data.hour }}</strong></div>
        </template>
      </Column>
      <Column v-for="day in days" :key="day.id" :field="day.name" :header="day.name">
        <template #body="{ data }">
          <Checkbox v-model="data[day.name]" binary @change="handleCheckboxChange(data, day)">
            <template #icon>
              <i :class="['pi', data[day.name] ? 'pi-check' : (data[day.name] === null ? 'pi-times' : 'pi-minus'), {'text-white': data[day.name]}]">
              </i>
            </template>
          </Checkbox>
        </template>
      </Column>
      <Toolbar class="border-0">
        <template #center>
          <ProgressSpinner v-if="loading" style="width: 50px; height: 50px" strokeWidth="8" fill="transparent"
                           animationDuration=".5s" aria-label="Custom ProgressSpinner" />
        </template>
      </Toolbar>
    </DataTable>
    <Toolbar class="border-0">
      <template #center>
        <Button icon="pi pi-arrow-circle-left" class="p-button-primary mr-3" label="Guardar" @click="postData" />
      </template>
    </Toolbar>
  </div>
</template>


<script>
import apiService from "@/service/api.service";
import { API } from "@/utils/modules";
import { useToast } from "primevue/usetoast";

export default {
  name: "ScheduleView",
  data() {
    return {
      days: [],
      hours: [],
      tableData: [],

      schedule: [],
      toast: useToast(),
      loading: false,
    };
  },

  methods: {
    async getSchedule() {
      try {
        this.loading = true;

        const daysResponse = await apiService.getData(API, "days");
        this.days = daysResponse.data.days;

        const hoursResponse = await apiService.getData(API, "hours");
        this.hours = hoursResponse.data.hours;

        const scheduleResponse = await apiService.getData(API, "schedules");
        this.schedule = scheduleResponse.data;

        this.generateTableData();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.toast.add({
          severity: "error",
          summary: "Error",
          detail: "No se pudieron cargar los datos.",
          life: 3000,
        });
      }
    },

    generateTableData() {
      if (!this.schedule) {
        return;
      }

      this.tableData = this.hours.map(hour => {
        const row = { hour: hour.name, hourID: hour.id };

        this.days.forEach(day => {
          const scheduleEntry = this.schedule.find(entry =>
              entry.dayID === day.id && entry.hourID === hour.id
          );
          row[day.name] = scheduleEntry ? scheduleEntry.status === '1' : '0';
        });

        return row;
      });
    },

    handleCheckboxChange(row, day) {
      const existingSchedule = this.schedule.find(entry =>
          entry.dayID === day.id && entry.hourID === row.hourID
      );

      if (existingSchedule) {
        existingSchedule.status = row[day.name] ? '1' : '0';
      } else {
        this.schedule.push({
          dayID: day.id,
          hourID: row.hourID,
          status: row[day.name] ? '1' : '0'
        });
      }
    },

    getUnselectedDaysAndHours() {
      const unselected = [];
      this.tableData.forEach(row => {
        this.days.forEach(day => {
          if (!row[day.name]) {
            unselected.push({
              hourID: row.hourID,
              dayID: day.id,
              status: '0'
            });
          }
        });
      });

      unselected.forEach(item => {
        const existingSchedule = this.schedule.find(entry =>
            entry.dayID === item.dayID && entry.hourID === item.hourID
        );

        if (existingSchedule) {
          existingSchedule.status = item.status;
        } else {
          this.schedule.push(item);
        }
      });

      return this.schedule;
    },

    postData() {
      const updatedSchedule = this.getUnselectedDaysAndHours();
      this.loading = true;
      apiService.update(API, "schedules", updatedSchedule).then(
          () => {
            this.loading = false;
            this.toast.add({
              severity: "success",
              summary: "Éxito",
              detail: "Se actualizó correctamente el horario.",
              life: 10000,
            });
          },
          (error) => {
            this.loading = false;
            this.toast.add({
              severity: "error",
              summary: "Error",
              detail: error.response.data,
              life: 10000,
            });
          }
      );
    },
  },


  mounted() {

    this.getSchedule();
  },
};
</script>