<template>
  <div>
    <GenericCrud :formType="'GenericForm'" title="Desempeño" path="performances" property-name="name"
                 :columns-table="columns()" :form-fields="fields"
                 :module="API()"/>
  </div>
</template>
<script>

import {Performances} from "@/entities/entities";
import GenericCrud from "@/components/GenericCrud.vue";
import {API} from "@/utils/modules";

export default {
  methods: {
    API() {
      return API
    },
    columns() {
      return Performances
    }
  },
  components: {
    GenericCrud,
  },
  data() {
    return {
      fields: [
        {
          id: 'name',
          label: 'Nombre',
          model: 'name',
          type: 'text',
          required: true,
          timeOnly: true,
          props: {autofocus: true}
        },
        {
          id: 'initialRange',
          label: 'Rango Inicial',
          model: 'initialRange',
          type: 'number',
          minFractionDigits: 2,
          required: true,
          props: {autofocus: true}
        },
        {
          id: 'finalRange',
          label: 'Rango Final',
          model: 'finalRange',
          type: 'number',
          minFractionDigits: 2,
          required: true,
          props: {autofocus: true}
        },
      ]
    };
  },
};
</script>
