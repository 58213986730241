<template>
  <li :class="{ 'layout-root-menuitem': root, 'active-menuitem': isActiveMenu }">
    <div v-if="root && item.visible !== false" class="layout-menuitem-root-text">{{ item.label }}</div>
    <a v-if="(!item.to || item.items) && item.visible !== false" :href="item.url" @click="itemClick($event, item, index)" :class="item.class" :target="item.target" tabindex="0">
      <i :class="item.icon" class="layout-menuitem-icon"></i>
      <span class="layout-menuitem-text">{{ item.label }}</span>
      <i class="pi pi-fw pi-angle-down layout-submenu-toggler" v-if="item.items"></i>
    </a>
    <router-link v-if="item.to && !item.items && item.visible !== false" @click="itemClick($event, item, index)" :class="[item.class, { 'active-route': checkActiveRoute(item) }]" tabindex="0" :to="item.to">
      <i :class="item.icon" class="layout-menuitem-icon"></i>
      <span class="layout-menuitem-text">{{ item.label }}</span>
      <i class="pi pi-fw pi-angle-down layout-submenu-toggler" v-if="item.items"></i>
    </router-link>
    <Transition v-if="item.items && item.visible !== false" name="layout-submenu">
      <ul v-show="root ? true : isActiveMenu" class="layout-submenu">
        <app-menu-item v-for="(child, i) in item.items" :key="i" :index="i" :item="child" :parentItemKey="itemKey" :root="false"></app-menu-item>
      </ul>
    </Transition>
  </li>
</template>

<script>
import { useRoute } from 'vue-router';
import { useLayout } from '@/components/composables/layout';
import AppMenuItem from './AppMenuItem.vue';

export default {
  name: 'AppMenuItem',
  components: {
    AppMenuItem
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      default: 0
    },
    root: {
      type: Boolean,
      default: true
    },
    parentItemKey: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isActiveMenu: false,
      itemKey: null
    };
  },
  computed: {
    route() {
      return useRoute();
    },
    layout() {
      return useLayout();
    }
  },
  methods: {
    itemClick(event, item) {
      if (item.disabled) {
        event.preventDefault();
        return;
      }

      const { overlayMenuActive, staticMenuMobileActive } = this.layout.layoutState;

      if ((item.to || item.url) && (staticMenuMobileActive.value || overlayMenuActive.value)) {
        this.layout.onMenuToggle();
      }

      if (item.command) {
        item.command({ originalEvent: event, item: item });
      }

      const foundItemKey = item.items ? (this.isActiveMenu ? this.parentItemKey : this.itemKey) : this.itemKey;

      this.layout.setActiveMenuItem(foundItemKey);
    },
    checkActiveRoute(item) {
      return this.route.path === item.to;
    }
  },
  watch: {
    'layout.layoutConfig.activeMenuItem.value'(newVal) {
      this.isActiveMenu = newVal === this.itemKey || newVal.startsWith(this.itemKey + '-');
    }
  },
  beforeMount() {
    this.itemKey = this.parentItemKey ? this.parentItemKey + '-' + this.index : String(this.index);
    const activeItem = this.layout.layoutState.activeMenuItem;
    this.isActiveMenu = activeItem === this.itemKey || activeItem ? activeItem.startsWith(this.itemKey + '-') : false;
  }
};
</script>

<style lang="scss" scoped></style>

