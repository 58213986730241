<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Chip class="h-2.5rem mr-3 mt-3">
          <i class="pi pi-tags" style="font-size: 2rem"></i>
          <span class="ml-2 font-medium">Curso: <strong>{{ assignment.course.name }}</strong></span>
        </Chip>
        <Chip class="h-2.5rem mr-3 mt-3">
          <i class="pi pi-book" style="font-size: 2rem"></i>
          <span class="ml-2 font-medium">Asignatura: <strong>{{ assignment.subject.name }}</strong></span>
        </Chip>
        <Chip class="h-2.5rem mr-3 mt-3">
          <i class="pi pi-user" style="font-size: 2rem"></i>
          <span class="ml-2 font-medium">Estudiante: <strong>{{ student }}</strong></span>
        </Chip>
      </div>
      <h5> Seleccione los conceptos correspondientes, para el estudiante seleccionado</h5>
      <div class="card">
        <Toast/>
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="ml-3">
              <div class="formgrid grid">
                <div>
                  <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew"/>
                </div>
                <div>
                  <Button label="Eliminar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"
                          :disabled="!selected || !selected.length"/>
                </div>
              </div>
            </div>
          </template>
        </Toolbar>
        <DataTable
            ref="dt"
            :value="records"
            v-model:selection="selected"
            dataKey="id"
            :paginator="true"
            sortMode="multiple"
            removableSort
            :rows="10"
            stripedRows
            :filters="filters"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 25]"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
        >
          <template #header>
            <div class="formgrid grid justify-content-end">
                <span class="p-input-icon-left">
                  <i class="pi pi-search mr-2 mt-2"/>
                </span>
              <div>
                <InputText v-model="filters['global'].value" placeholder="Search..."/>
              </div>

            </div>
          </template>
          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header" :sortable="true"
                  style="max-width: 50rem">
            <template #body="{ data, field }">
              <div v-if="col.type === 'percentage'">
                {{ formatNestedValue(getNestedValue(data, field)) }} %
              </div>
              <div v-else>
                {{ formatNestedValue(getNestedValue(data, field)) }}
              </div>
            </template>
          </Column>
          <Column headerStyle="min-width:10rem;" bodyStyle="text-align: right;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                      @click="updateRecordSelected(slotProps.data)"/>
              <Button icon="pi pi-trash" class="p-button-rounded mt-2" severity="warn"
                      @click="confirmDelete(slotProps.data)"/>
            </template>
          </Column>
          <ColumnGroup v-if="totales" type="footer">
            <Row>
              <Column footer="Total:" :colspan="2" footerStyle="text-align:right" :footer-class="footerClass"/>
              <Column :footer="total" :footer-class="footerClass"/>
            </Row>
          </ColumnGroup>
        </DataTable>
        <component :is="currentFormComponent"
                   :edit="edit"
                   title="Concepto Descriptivo"
                   :active="formDialog"
                   @update:active="formDialog = $event"
                   :object="record"
                   @record-created="onRecordCreated"
                   @record-updated="onRecordUpdated"
                   :fields="fields"
                   :dropdown-options="dropdownOptions"
        />
        <Dialog v-model:visible="deleteDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
            <span v-if="record">¿Esta seguro de eliminar el registro <b>{{ record[this.propertyName] }}</b>?</span>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDialog = false"/>
            <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteRecord"/>
          </template>
        </Dialog>
        <Dialog v-model:visible="deleteListDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
            <span v-if="record">¿Esta Seguro de eliminar los registros seleccionados?</span>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteListDialog = false"/>
            <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteSelected"/>
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>
<script>
import apiService from "@/service/api.service";
import {FilterMatchMode} from "@primevue/core/api";
import {markRaw} from 'vue';
import {useToast} from "primevue/usetoast";

export default {
  props: {
    assignment: {
      type: Object,
      required: false
    },
    student: {
      type: String,
      required: false
    },
    period: {
      type: Object,
      required: false
    },
    selectedConcepts: {
      type: Object,
      required: false
    },
    formType: {
      type: String,
      required: true
    },
    columnsTable: {
      type: Object,
      required: true
    },
    propertyName: {
      type: String,
      required: true
    },
    path: {
      type: String,
      required: true
    },
    module: {
      type: String,
      required: true
    },
    dropdownOptions: {
      type: Object,
      required: false
    },
    totales: {
      type: Boolean,
      required: false
    },
    totalColumn: {
      type: String,
      required: false
    }
  },
  name: "ConceptDescriptionComponent",
  data() {
    return {
      toast: useToast(),
      currentFormComponent: null,
      selectedItem: null,
      formDialog: false,
      records: [],
      selected: [],
      columns: [],
      filters: {},
      record: {},
      deleteDialog: false,
      deleteListDialog: false,
      edit: false,
      fields: [
        {
          id: 'code',
          label: 'Código',
          model: 'code',
          type: 'number',
          required: true,
          props: {autofocus: true, max: 100}
        },
        {
          id: 'description',
          label: 'Descripción',
          model: 'description',
          type: 'text',
          required: true,
          props: {autofocus: true}
        },
      ]
    };
  },
  methods: {
    getNestedValue(obj, path) {
      return path.split('.').reduce((acc, part) => {
        if (Array.isArray(acc)) {
          return acc.map(item => item && item[part]);
        } else {
          return acc && acc[part];
        }
      }, obj);
    },
    formatNestedValue(value) {
      if (Array.isArray(value)) {
        return value.flat().filter(v => v !== undefined).join(', ');
      }
      return value;
    },
    onRecordCreated(newRecord) {
      this.record = newRecord;
      this.create();
    },
    onRecordUpdated(updated) {
      this.record = updated;
      this.update();
    },
    updateRecordSelected(edit) {
      this.record = {...edit};
      this.edit = true;
      this.formDialog = true;
    },
    confirmDelete(editCliente) {
      this.record = editCliente;
      this.deleteDialog = true;
    },
    openNew() {
      this.record = {};
      this.edit = false;
      this.submitted = false;
      this.formDialog = true;
    },
    confirmDeleteSelected() {
      this.deleteListDialog = true;
    },
    initFilters() {
      this.filters = {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS}
      };
    },
    deleteRecord() {
      this.delete()
    },
    deleteSelected() {
      this.deleteList()
    },
    deleteList() {
      return apiService.deleteList(this.module, this.path, this.selected).then(
          (response) => {
            this.records = this.records.filter((val) => !this.selected.includes(val));
            this.deleteListDialog = false;
            this.selected = null;
            this.toast.add({severity: 'success', summary: 'Completado', detail: response.data, life: 3000});
          },
          (error) => {
            this.toast.add({severity: 'error', summary: 'Completado', detail: error.response.data, life: 10000});
          }
      );
    },
    delete() {
      return apiService.delete(this.module, this.path, this.record).then(
          (response) => {
            this.records = this.records.filter((val) => val.id !== this.record.id);
            this.deleteDialog = false;
            this.record = {};
            this.toast.add({severity: 'success', summary: 'Completado', detail: response.data, life: 3000});
            this.getData()
          },
          (error) => {
            this.toast.add({severity: 'error', summary: 'Completado', detail: error.response.data, life: 10000});
            this.deleteDialog = false;
          }
      );
    },
    create() {
      this.record.assignment = this.assignment;
      this.record.period = this.period;
      return apiService.create(this.module, this.path, this.record).then(
          () => {
            this.toast.add({severity: 'success', summary: 'Completado', detail: 'Registro Creado!', life: 3000});
            this.formDialog = false;
            this.record = {};
            this.getData();
          },
          (error) => {
            this.toast.add({severity: 'error', summary: 'Successful', detail: error.response.data, life: 10000});
          }
      );
    },
    update() {
      return apiService.update(this.module, this.path, this.record).then(
          () => {
            this.toast.add({severity: 'success', summary: 'Completado', detail: 'Registro Actualizado!', life: 3000});
            this.formDialog = false;
            this.record = {};
            this.getData()
          },
          (error) => {
            this.toast.add({severity: 'error', summary: 'Successful', detail: error.response.data, life: 10000});
          }
      );
    },
    async getData() {
      try {
        const response = await apiService.getDataPost(this.module, this.path+'/assignment',this.assignment);
        this.records = response.data.filter(concept => concept.period.name === this.period.name);
      } catch (error) {
        this.message = error.message || 'Error al obtener datos';
      }
    },
    getSelectedItems() {
      return this.selected;
    },
    async loadComponent(componentName) {
      if (componentName) {
        try {
          const component = await import(`./forms/${componentName}.vue`);
          this.currentFormComponent = markRaw(component.default);
        } catch (error) {
          console.error(`Error loading component ${componentName}:`, error);
        }
      }
    }
  },
  async mounted() {
    await this.getData();
    this.selected = this.records.filter(record => this.selectedConcepts.includes(record.id));
    await this.loadComponent(this.formType);
  },
  beforeMount() {
    this.initFilters();
    this.columns = this.columnsTable;
  },
};
</script>
