<template>
  <div>
    <GenericCrud :formType="'GenericForm'" title="Año Escolar" path="years" property-name="name"
                 :columns-table="columns()" :form-fields="fields"
                 :module="API()" :dropdown-options="dropdownOptions()"/>
  </div>
</template>
<script>

import {Years} from "@/entities/entities";
import GenericCrud from "@/components/GenericCrud.vue";
import {API} from "@/utils/modules";

export default {
  components: {
    GenericCrud,
  },
  data() {
    return {
      fields: [
        {id: 'name', label: 'Nombre', model: 'name', type: 'text', required: true, props: {autofocus: true}},
        {
          id: 'state', label: 'Estado', options: 'states', model: 'state', type: 'dropdown', required: true,
          props: {
            placeholder: 'Seleccione un estado',
            showClear: true,
            filter: true,
            optionLabel: 'name',
            optionValue: 'code'
          }
        },
      ]
    };
  },
  methods: {
    API() {
      return API
    },
    columns() {
      return Years
    },
    dropdownOptions() {
      return {
        states: [
          {code: 'ABIERTO', name: 'ABIERTO'},
          {code: 'CERRADO', name: 'CERRADO'},
        ],
      };
    },
  },
};
</script>
