<template>
  <div class="card">
    <Chip class="h-2.5rem mr-3 mt-3">
      <i class="pi pi-tags" style="font-size: 2rem"></i>
      <span class="ml-2 font-medium">Curso: <strong>{{ assignment.course.name }}</strong></span>
    </Chip>
    <Chip class="h-2.5rem mr-3 mt-3">
      <i class="pi pi-book" style="font-size: 2rem"></i>
      <span class="ml-2 font-medium">Asignatura: <strong>{{ assignment.subject.name }}</strong></span>
    </Chip>
    <Chip class="h-2.5rem mr-3 mt-3">
      <i class="pi pi-user" style="font-size: 2rem"></i>
      <span class="ml-2 font-medium">Estudiante: <strong> {{ student }}</strong></span>
    </Chip>
  </div>
  <div class="card">
    <DataTable :value="sortedFinalScores" ref="dt"
               dataKey="id"
               :paginator="true"
               sortMode="multiple"
               removableSort
               :rows="5"
               stripedRows
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
               currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros">
      <Column field="period.name" header="Periodo"></Column>
      <Column field="finalGrade" header="Nota Periodo" style="max-width: 3rem"></Column>
      <Column field="period.percentage" header="Porcentaje Periodo" style="max-width: 3rem"></Column>
      <Column header="Valor Porcentual" style="max-width: 3rem">
        <template #body="{data}">
          {{ data.finalGrade * data.period.percentage / 100 }}
        </template>
      </Column>
      <ColumnGroup type="footer">
        <Row>
          <Column footer="Promedio:" footerStyle="text-align:right;max-width: 3rem"/>
          <Column :footer="average" footerStyle="max-width: 3rem"/>
          <Column footer="Definitiva:" footerStyle="text-align:right;max-width: 3rem"/>
          <Column :footer="total" footerStyle="max-width: 3rem"/>
        </Row>
        <Row>
          <Column footer="Nota Faltante:" colspan="3" footerStyle="text-align:right;max-width: 3rem"/>
          <Column :footer="missingGrade" footerStyle="max-width: 3rem"/>
        </Row>
      </ColumnGroup>

    </DataTable>
  </div>
</template>

<script>
export default {
  name: "FinalScoreStudentComponent",
  props: {
    finalScores: Object,
    student: {
      type: String,
      required: false
    },
    period: {
      type: Object,
      required: false
    },
    assignment: {
      type: Object,
      required: false
    },
  },
  data() {
    return {
      periodOrder: ["Primer Periodo", "Segundo Periodo", "Tercer Periodo", "Cuarto Periodo"],
    }
  },
  methods: {
    roundToDecimals(number, decimals) {
      if (number === undefined) {
        return (0).toFixed(decimals);
      } else {
        const factor = Math.pow(10, decimals);
        let rounded = Math.round(number * factor) / factor;
        return rounded.toFixed(Math.max(1, decimals));
      }
    },
  },
  computed: {
    sortedFinalScores() {
      return this.finalScores.slice().sort((a, b) => {
        return this.periodOrder.indexOf(a.period.name) - this.periodOrder.indexOf(b.period.name);
      });
    },
    average() {
      return this.roundToDecimals(this.finalScores
          .reduce((total, object) => total + object.finalGrade, 0) / this.finalScores.length,1);
    },
    total() {
      return this.roundToDecimals(this.finalScores
          .reduce((total, object) => total + object.finalGrade * object.period.percentage / 100, 0),1);
    },
    totalPercentage() {
      return this.finalScores.reduce((total, object) => total + object.period.percentage, 0);
    },
    missingGrade() {
      const requiredAverage = 3;
      const totalPercentage = 100;
      const currentSum = this.finalScores.reduce((sum, score) => sum + score.finalGrade * (score.period.percentage / totalPercentage), 0);
      const missingPercentage = (totalPercentage - this.totalPercentage) / totalPercentage;
      const missingGrade = (requiredAverage - currentSum) / missingPercentage;
      return missingGrade >= 0 ? this.roundToDecimals(missingGrade,1) : null;
    }
  }
}
</script>
