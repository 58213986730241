<template>
  <div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <div class="flex flex-column align-items-center justify-content-center">
      <Image :width="svgWidth" :height="svgHeight"/>
      <div
          style="border-radius: 56px; padding: 0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)">
        <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius: 53px">
          <div class="text-center mb-5">
            <div class="text-900 text-3xl font-medium mb-3">Bienvenido!</div>
            <span class="text-600 font-medium">Ingresa los datos para acceder al sistema</span>
          </div>
          <small v-if="!passwordValid" id="username-help" class="p-error">Por favor valida los datos de ingreso.</small>
          <div>
            <div class="field p-fluid">
              <label for="email1" class="block text-900 text-xl font-medium mb-2">Usuario</label>
              <InputText id="email1" type="text" placeholder="Email address"
                         class="w-full md:w-30rem mb-3"
                         :class="{ 'p-invalid': !emailValid }"
                         @change="validateEmail"
                         style="padding: 1rem" :required="true" v-model="user.email" :invalid="!passwordValid"/>
              <div>
              <small v-if="!emailValid" style="color: red;">El correo electrónico ingresado no es válido.</small>
              </div>
            </div>
            <div class="field p-fluid">
              <label for="password1" class="block text-900 font-medium text-xl mb-2">Contraseña</label>
              <Password @change="resetMessage" id="password1" :required="true" v-model="user.password"
                        placeholder="Password" :toggleMask="true"
                        class="w-full mb-3"
                        :inputStyle="{ padding: '1rem' }" inputClass="w-full" :invalid="!passwordValid"
                        :feedback="false"></Password>
            </div>
            <div class="flex align-items-end justify-content-between mb-5 gap-5">
              <a class="font-medium no-underline ml-2 text-right cursor-pointer" style="color: var(--primary-color)">¿Olvidaste
                la contraseña?</a>
            </div>
            <Button :disabled="submitButtonDisabled" label="Iniciar Sesión" @click="handleSubmit"
                    class="w-full p-3 text-xl"></Button>
            <Toast></Toast>

            or login with

            <Button @click="googleSubmit"> Google</Button>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Image from '@/components/Image.vue';
import {mapActions} from "vuex";

export default {
  components: {
    Image
  },
  data() {
    const email = '';
    const password = '';
    const checked = false;
    return {
      user: {
        email,
        password
      },
      emailValid: true,
      passwordValid: true,
      checked,
      message: [],
      svgWidth: 400,
      svgHeight: 250
    };
  },
  computed: {
    submitButtonDisabled() {
      return !(this.user.email.trim() && this.user.password.trim() && this.emailValid);
    }
  },
  methods: {
    ...mapActions(['login']),
    ...mapActions(['loginGoogle']),
    resetMessage() {
      this.passwordValid = true;
    },
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.emailValid = emailPattern.test(this.user.email);
    },
    googleSubmit(){
      this.loginGoogle().then(() => {
        this.$router.push({ name: 'home' });
      })
          .catch((error) => {
            this.passwordValid = false;
            if(error.response===undefined)
            this.$toast.add({severity: 'error', summary: 'Error de Autenticación', detail: error, life: 5000});
            else
              this.$toast.add({severity: 'error', summary: 'Error de Autenticación', detail: error.response.data, life: 5000});

          });
    },
    handleSubmit() {
      this.passwordValid = true;
      this.login({user: this.user.email, password: this.user.password})
          .then(() => {
            this.$router.push({ name: 'dashboard' });
          })
          .catch((error) => {
            this.passwordValid = false;
            // this.$toast.add({severity: 'error', summary: 'Error de Autenticación', detail: error, life: 5000});
            if(error.response===undefined)
              this.$toast.add({severity: 'error', summary: 'Error de Autenticación', detail: error, life: 5000});
            else
              this.$toast.add({severity: 'error', summary: 'Error de Autenticación', detail: error.response.data, life: 5000});
          });
    },
  }
};
</script>

