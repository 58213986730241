<template>
  <div>
    <GenericCrud :formType="'GenericForm'" title="Periodos Académicos" path="periods" property-name="name"
                 :columns-table="columns()" :form-fields="fields"
                 :module="API()" :dropdown-options="dropdownOptions()" :totales="true" total-column="percentage"/>
  </div>
</template>
<script>

import {Periods} from "@/entities/entities";
import GenericCrud from "@/components/GenericCrud.vue";
import {API} from "@/utils/modules";
import apiService from "@/service/api.service";

export default {
  components: {
    GenericCrud,
  },
  data() {
    return {
      years: [],
      fields: [
        {id: 'name', label: 'Nombre', model: 'name', type: 'text', required: true, props: {autofocus: true}},
        {
          id: 'percentage',
          label: 'Porcentaje',
          model: 'percentage',
          type: 'number',
          required: true,
          props: {autofocus: true, max: 100}
        },
        {
          id: 'year', label: 'Año Vigencia', options: 'years', model: 'year', type: 'dropdown', required: true,
          props: {
            placeholder: 'Seleccione un año de vigencia',
            showClear: true,
            filter: true,
            optionLabel: 'name',
          }
        },
        {
          id: 'state', label: 'Estado', options: 'states', model: 'state', type: 'dropdown', required: true,
          props: {
            placeholder: 'Seleccione un estado',
            showClear: true,
            filter: true,
            optionLabel: 'name',
            optionValue: 'code'
          }
        },
      ]
    };
  },
  methods: {
    API() {
      return API
    },
    columns() {
      return Periods
    },
    getYears() {
      apiService.getData(API, 'years')
          .then(response => {
            this.years = response.data.filter(year => year.state === 'ABIERTO');
          })
          .catch(error => {
            this.message = error.message || 'Error al obtener datos';
          });
    },
    dropdownOptions() {
      return {
        states: [
          {code: 'ABIERTO', name: 'ABIERTO'},
          {code: 'CERRADO', name: 'CERRADO'},
        ],
        years: this.years
      };
    },
  },
  mounted() {
    this.getYears()
  }

};
</script>
